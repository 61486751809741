import { Component } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setAuthentication } from '../../redux/slices/authSlice';
import {
    setLanguages,
    setDefaultLanguage,
    setLangLabels,
    refreshLangLabels,
    setFirebaseToken
} from '../../redux/slices/langSlice';
import { setSelectedChild } from '../../redux/slices/selectedChildSlice';
import { toast } from 'react-toastify';
import './login.css';
import logo from '../../assets/images/logo.png';
import axios from 'axios';
import { t } from '../../helpers/translation_helper';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { firebaseApp } from '../../services/firebase';

const apiURL = process.env.REACT_APP_API_URL;

/**
 * Login Component
 */
class Login extends Component {
    /**
     * @constructor
     * @param {any} props
     */
    constructor(props) {
        super(props);

        this.state = {
            working: false,
            username: '',//0504051555
            password: '',//0504051555
            langLabels: [],
            langOffset: 0,
            loader: false,
        };
    }

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.props.refreshLangLabels(this.state.langOffset)
        axios.get(apiURL + 'language/get-languages').then((res) => {
            if (res.data.type) {
                this.props.setLanguages(res.data.data);
                const defaultLanguage = res.data.data.find((item) => {
                    return item.lang_name == 'English';
                });
                this.props.setDefaultLanguage(defaultLanguage);
            } else {
                toast.error('Something went wrong!');
            }
        });
    }


    /**
     * Handle Field
     * @param {event} e Event
     * @param {string} field Field name
     */
    handleField = (e, field) => {
        this.setState({ [field]: e.target.value });
    };

    /**
     * Handle Login
     * @param {event} e Event
     */
    handleLogin = async (e) => {
        e.preventDefault();

        this.setState({ working: true });

        const { username, password } = this.state;

        if (username == '' || password == '') {
            this.setState({ working: false });
            toast.error(t('Username and password is required.'));
            return;
        }

        try {
            const res = await axios.get(apiURL + 'login', {
                withCredentials: true,
                auth: {
                    username: username,
                    password: password,
                },
            });

            if (res.data.type) {
                if (res.data.data.children.length > 0) {
                    this.setState({ working: false });
                    const { setAuthentication, setSelectedChild, setFirebaseToken } = this.props;


                    let isBrowserSupported = await isSupported()
                    if (isBrowserSupported) {
                        const messaging = getMessaging(firebaseApp);
                        getToken(messaging, {
                            vapidKey:
                                'BJ3u-j-0W2m1it06nryDvW8dV9X7uzl6i9la_lyEKPLYkmZHVxqGpCwF8l-vXCHx6sAcOa3O2WGnVdAsVbA2-Rc',
                        })
                            .then((currentToken) => {
                                if (currentToken) {
                                    setFirebaseToken(currentToken);
                                    this.registerFirebase(currentToken, res.data.data.centerData.center_id, res.data.data.centerData.center_timezone, res.data.data.loginData.user_id, res.data.data.loginData.role_id, res.data.data.loginData.auth_key);
                                } else {
                                    console.log(
                                        'No registration token available. Request permission to generate one.'
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log(
                                    'An error occurred while retrieving token. ',
                                    err
                                );
                            });
                    }

                    setAuthentication(res.data.data);
                    setSelectedChild(res.data.data.children[0]);
                } else {
                    this.setState({ working: false });
                    toast.error(t('No children have been found for this parent!'));
                }
            } else {
                this.setState({ working: false });
                toast.error(res.data.message);
            }
        } catch (err) {
            this.setState({ working: false });
            toast.error('Something went wrong!');
            console.log(err);
        }
    };


    /**
   * Register Firebase
   * @param {String} currentToken
   * @param {Number} center_id
   * @param {Number} center_timezone
   * @param {Number} user_id
   * @param {Number} role_id
   * @param {String} auth_key
   */
    registerFirebase = async (
        currentToken,
        center_id,
        center_timezone,
        user_id,
        role_id,
        auth_key
    ) => {
        let fData = new FormData();
        fData.append('currentToken', currentToken);
        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        fData.append('user_id', user_id);
        fData.append('role_id', role_id);

        try {
            const res = await axios.post(
                apiURL + 'authentication/register-firebase',
                fData,
                {
                    params: {
                        auth_key: auth_key,
                        appname: process.env.REACT_APP_NAME,
                    },
                }
            );
            // 
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while saving token!'));

        }
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const { working, username, password, loader } = this.state;

        if (loader) {
            return (
                <div id='loader'>
                    <img src={require('../../assets/images/iedu-loader.gif')} alt='iEducore' width='120' />
                    {t('Please wait...')}
                    <br />
                    {t('Setting up student dashboard')}
                </div>
            );
        } else {
            return (
                <div id='login-form'>
                    <div className='text-center'>
                        <img src={logo} alt='iEducore' width='200' />
                    </div>
                    <Form onSubmit={this.handleLogin}>
                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>{t('Username')}</Form.Label>
                            <Form.Control
                                value={username}
                                type='text'
                                placeholder='Username'
                                onChange={(e) =>
                                    this.handleField(e, 'username')
                                }
                            />
                        </Form.Group>
                        <Form.Group
                            className='mb-3'
                            controlId='formBasicPassword'>
                            <Form.Label>{t('Password')}</Form.Label>
                            <Form.Control
                                value={password}
                                type='password'
                                placeholder='Password'
                                onChange={(e) =>
                                    this.handleField(e, 'password')
                                }
                            />
                        </Form.Group>
                        <Button
                            variant='secondary'
                            type='submit'
                            disabled={working}>
                            {t('Login')}{' '}
                            {working ? (
                                <Spinner
                                    as='span'
                                    animation='grow'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                />
                            ) : (
                                ''
                            )}
                        </Button>
                    </Form>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    langLabels: state.language.langLabels,
});

const mapDispatchToProps = () => ({
    setAuthentication,
    setLanguages,
    setDefaultLanguage,
    setLangLabels,
    setSelectedChild,
    refreshLangLabels,
    setFirebaseToken,
});

export default connect(mapStateToProps, mapDispatchToProps())(Login);
