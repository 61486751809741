import { useCallback, useEffect, useState } from "react";
/**
 * General Helpers
 */
export const trimThisDown = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
};
export const getExtension = (name) => {
    let nameSplit = name.split('.')
    let ext = nameSplit[1].toLowerCase()
    if (ext == 'pdf') {
        return 'pdf'
    } else if (ext == 'wav' || ext == 'm4a' || ext == 'mp3' || ext == 'mp4') {
        return 'audio'
    } else {
        return 'image'
    }
}

export const getUrlParam = () => {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

export function changeColorOpacity(color, opacity) {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    const rgbRegex = /^rgb(a)?\(\s*((25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\s*,\s*){2}(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\s*(,\s*\d+(\.\d+)?)?\)$/i;
    const hslRegex = /^hsla?\(\s*((360|3[0-5]\d|[12]\d{2}|[1-9]\d|\d)\s*,\s*){2}(360|3[0-5]\d|[12]\d{2}|[1-9]\d|\d)\s*(,\s*\d+(\.\d+)?)?\)$/i;

    // IF HEX
    if (hexRegex.test(color)) {
        if (color.length > 7)
            color = color.substring(0, color.length - 2);
        const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
        let opacityHex = _opacity.toString(16).toUpperCase()
        if (opacityHex.length == 1)
            opacityHex = "0" + opacityHex;
        return color + opacityHex;
    }
    // IF RGB OR RGBA
    else if (rgbRegex.test(color)) {
        if (color.substring(0, 4) === 'rgba') {
            return color.replace(/[^,]+(?=\))/, opacity)
        } else {
            return color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba')
        }
    } else {
        return color
    }

}