import React, { Component } from 'react';
import { Container, Card, Spinner, Table, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import './timetable.css';
import moment from 'moment';
const ContrastColor = require('contrast-color');

// API URL
const apiURL = process.env.REACT_APP_API_URL;

/**
 * Timetable Component
 */
class Timetable extends Component {
    // State
    state = {
        refreshing: false,
        timetable: [],
        weekdays: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getCompleteTimetable();
    }

    /**
     * Get Complete Timetable
     */
    getCompleteTimetable = async () => {
        this.setState({ refreshing: true, timetable: [] })
        const { auth_key, center_id } = this.props.authData.loginData;
        const { agegroup_id, class_theme, class_id } =
            this.props.selectedChild;

        let request_params = {
            center_id,
            agegroup_id,
            class_theme,
            class_id,
            auth_key,
            appname: process.env.REACT_APP_NAME,
        };

        try {
            const res = await axios.get(
                apiURL + 'dashboard/get-complete-timetable',
                {
                    params: request_params,
                }
            );

            this.setState({
                timetable: res.data.data,
                refreshing: false
            });
        } catch (err) {
            toast.error('Something went wrong while fetching timetable!');
            console.log(err);
        }
    };

    /**
     * Render Materials
     * @returns {HTMLElement}
     */
    renderPeriods = () => {
        const { weekdays, timetable } = this.state;
        let weekend_days = this.props.authData.centerData.weekend_days.split(',')

        return weekdays.map((item, index) => {
            console.log('item', item);
            if (!weekend_days.includes(item.toLocaleLowerCase())) {
                const subjects = timetable[item].map((item, index) => {
                    const cc = new ContrastColor({
                        bgColor: item.as_color,
                        threshold: 250,
                    });
                    const defaultFgColor = cc.contrastColor();
                    return (
                        <td>
                            {item.acs_name
                                ? (
                                    <span
                                        className='badge'
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            backgroundColor: item.as_color,
                                            color: defaultFgColor,
                                        }}>
                                        {item.acs_name
                                        }
                                        <br />
                                        <small>({item.class_theme})</small>
                                    </span>
                                ) : (
                                    t('FREE')
                                )}
                        </td>
                    );
                });

                return (
                    <tr key={index}>
                        <td>{item}</td>
                        {subjects}
                    </tr>
                );
            }
        });
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const { timetable, weekdays } = this.state;

        console.log('timetable', timetable);

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Timetable')} backBtn={true} func={[this.getCompleteTimetable]} showChildSelector={true} />

                        <div className='grey-section'>
                            {(!this.state.refreshing && timetable.Friday)
                                ? <Table className='timetable-table text-center hover'>
                                    <thead>
                                        <tr>
                                            <th>{t('Day & Time')}</th>
                                            {timetable.Friday.map(
                                                (item, index) => (
                                                    <th key={index}>
                                                        {moment(
                                                            item.time_start,
                                                            'HH:mm:ss'
                                                        ).format('HH:mm')}{' '}
                                                        -{' '}
                                                        {moment(
                                                            item.time_end,
                                                            'HH:mm:ss'
                                                        ).format('HH:mm')}
                                                        {' '}
                                                        {item.tp_type_name &&
                                                            <span style={{
                                                                borderColor: item.tp_type_color,
                                                                borderWidth: 1,
                                                                borderStyle: 'solid',
                                                                color: item.tp_type_color,
                                                                textTransform: 'uppercase',
                                                                borderRadius: 5,
                                                                paddingInline: 4,
                                                                paddingBlock: 2,
                                                                display: 'inline-block'
                                                            }}>
                                                                {item.tp_type_name}
                                                            </span>
                                                        }
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderPeriods()}</tbody>
                                </Table>
                                : <div style={{ height: '120px', flexDirection: 'column', }} className='d-flex justify-content-center align-items-center'>
                                    {timetable.length <= 0 && this.state.refreshing ? (
                                        <>
                                            <span className='mb-1'>
                                                <Spinner animation='grow' variant='dark' />
                                            </span>
                                            {t('Fetching timetable...')}
                                        </>
                                    ) : (
                                        t('No timetable available!')
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(Timetable);
