import { store } from "../redux/store";
import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;


// GET
export const GET = (endpoint, params) => {

  let state = store.getState()
  console.log('GETstate', state);
  let authData = state.auth.authData
  let auth_key = authData.authKey;
  let center_id = authData.centerData.center_id;
  let center_uuid = authData.centerData.center_uuid;
  let center_timezone = authData.centerData.center_timezone;
  let user_id = authData.loginData.user_id;
  let role_id = authData.loginData.role_id;

  let passingParams = {
    center_id,
    center_uuid,
    center_timezone,
    auth_key,
    user_id,
    role_id,
    appname: process.env.REACT_APP_NAME,
  }
  if (params) {
    if (params.params) {
      passingParams = { ...passingParams, ...params.params }
    }

    params = {
      ...params,
      params: passingParams
    }
  } else {
    params = { params: passingParams }
  }

  /**
  * API Requests/Responses Handler
  */
  return axios.get(apiURL + endpoint, params)
}

// POST
export const POST = (endpoint, data, params) => {
  let state = store.getState()
  let authData = state.auth.authData
  let auth_key = authData.authKey;
  let center_id = authData.centerData.center_id;
  let center_uuid = authData.centerData.center_uuid;
  let center_timezone = authData.centerData.center_timezone;
  let user_id = authData.loginData.user_id;
  let role_id = authData.loginData.role_id;

  let passingParams = {
    center_id,
    center_uuid,
    center_timezone,
    auth_key,
    user_id,
    role_id,
    appname: process.env.REACT_APP_NAME,
  }
  if (params) {
    if (params.params) {
      passingParams = { ...passingParams, ...params.params }
    }
    params = {
      ...params,
      params: passingParams
    }
  } else {
    params = { params: passingParams }
  }

  console.log('params', params);

  return axios.post(apiURL + endpoint, data, params)
}