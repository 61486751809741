import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Spinner,
  Button,
  Image,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { withParams } from '../../helpers/params_helper';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faBan,
  faCheck,
  faDownload,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './download-media.css';
import fileDownload from 'js-file-download';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from './JsZipUtils';
import ImageViewer from 'react-simple-image-viewer';
import { Black, White } from '../../helpers/brand_colors_helper';
import { Scrollbar } from 'react-scrollbars-custom';
import moment from 'moment';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Album Component
 */
class Album extends Component {
  constructor(props) {
    super(props)
    // States
    this.state = {
      pictures: [],
      currentIndex: 0,
      isViewerOpen: false,
      images: [],
      imagesParams: {
        userTimelineMediaOffset: 0,
        userTimelineMediaLimit: 50,
        refreshing: false,
        finished: false
      },
      working: false
    };
    this.scrollMedia = React.createRef()
  }


  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getPictures();
  }

  /**
   * Get Pictures
   */
  getPictures = async () => {
    let imagesParams = this.state.imagesParams
    if (imagesParams.refreshing || imagesParams.finished) {
      return
    }
    this.setState({ imagesParams: { ...imagesParams, refreshing: true } }, async () => {

      const { authData, params, selectedChild } = this.props;
      const { auth_key } = authData.loginData;
      const { center_id } = authData.centerData;
      let request_params = {
        center_id,
        album_id: params.album_id,
        class_id: selectedChild.class_id,
        child_id: selectedChild.child_id,
        auth_key: auth_key,
        userTimelineMediaOffset: imagesParams.userTimelineMediaOffset,
      };

      try {
        const res = await axios.get(
          apiURL + 'user-timeline/get-user-timeline-media',
          {
            params: request_params,
          }
        );

        const images_arr = res.data.map((item) => {
          let pictureURL = (item.utm_url)
            ? item.utm_url
            : (item.utm_folder)
              ? portalURL + this.props.authData.centerData.center_uuid + '/' + item.utm_folder + '/' + item.utm_name
              : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline_media/' + item.utm_name
          return pictureURL;
        });

        this.setState({
          pictures: this.state.pictures.concat(res.data),
          images: this.state.images.concat(images_arr),
          imagesParams: { ...imagesParams, refreshing: false, userTimelineMediaOffset: imagesParams.userTimelineMediaOffset + imagesParams.userTimelineMediaLimit, finished: (res.data.length <= 0) ? true : false },
        });
      } catch (err) {
        toast.error('Something went wrong while fetching pictures!');
        console.log(err);
        this.setState({
          imagesParams: { ...imagesParams, refreshing: false, finished: true },
        });
      }
    })
  };

  /**
   * Handle Download
   * @param {string} url
   * @param {string} filename
   */
  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        let ext = filename.split('.');
        fileDownload(res.data, chance.guid() + '.' + ext[1]);
      });
  };

  /**
   * Check / Uncheck Picture
   * @param {number} index
   */
  checkUncheck = (index) => {
    const { pictures } = this.state;

    if (pictures[index].checked === true) {
      pictures[index].checked = false;
    } else {

      // Limit restriction
      const limit = 24;
      const checkedPicsCount = pictures.filter(item => item.checked == true).length;
      if (checkedPicsCount == limit) {
        toast.info(t(`Allowed limit is ${limit} to download pictures!`));
        return;
      }

      pictures[index].checked = true;
    }

    this.setState({ pictures });
  };

  /**
   * Download Zip
   */
  downloadZip = () => {
    const { authData, params, selectedChild } = this.props;
    let album_name = params.album_name.replaceAll("%20", " ")
    const { pictures } = this.state;
    let selectedPictures = pictures.filter((v, i) => v.checked)
    if (selectedPictures.length <= 0) {
      toast.error('No Pictures Selected!');
      return
    }
    this.setState({ working: true }, () => {

      let zip = new JSZip();

      selectedPictures.map((item) => {
        let url = (item.utm_url)
          ? item.utm_url
          : (item.utm_folder)
            ? portalURL + this.props.authData.centerData.center_uuid + '/' + item.utm_folder + '/' + item.utm_name
            : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline_media/' + item.utm_name
        let ext = item.utm_name.split('.');
        zip.file(chance.guid() + '.' + ext[1], this.urlToPromise(url), {
          binary: true,
        });
      });

      zip.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(blob, selectedChild.child_name.replaceAll("%20", " ") + '_' + album_name.replaceAll("%20", " ") + '_' + moment().format('YYYYMMDDHHmmss') + '.zip');
        let pictures = this.state.pictures.map((v, i) => { return { ...v, checked: false } })
        this.setState({ pictures, working: false })
      });
    });
  };

  /**
   * URL To Promise
   * @param {string} url
   * @returns {Promise}
   */
  urlToPromise = (url) => {
    return new Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  openImageViewer = (index) => {
    this.setState({
      currentIndex: index,
      isViewerOpen: true,
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentIndex: 0,
      isViewerOpen: false,
    });
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    //  States
    const { pictures, imagesParams } = this.state;

    // Params
    const { album_name } = this.props.params;

    console.log('this.state', this.state);
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Album:') + ' ' + album_name} backBtn={true} />
            <Card
              className='border-0'
              style={{ borderRadius: 20 }}>
              <Card.Header>
                <Row className='mb-2 ms-2'>
                  <Col>
                    <Button variant='primary' size='sm'
                      style={{
                        borderRadius: 15,
                        padding: "5px 12px",
                        marginRight: 7
                      }}
                      onClick={() => this.downloadZip()}>
                      <FontAwesomeIcon icon={faDownload} color='#ffffff' />{' '}
                      {t('Download Selected')}
                      {this.state.working && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' className='ms-1' />}
                    </Button>
                    {(this.state.pictures.filter((v, i) => v.checked).length > 0) &&
                      <Button variant='warning' size='sm'
                        style={{
                          borderRadius: 15,
                          padding: "5px 12px"
                        }}
                        onClick={() => {
                          const pictures = this.state.pictures;
                          this.setState({
                            pictures: pictures.map(item => {
                              return {
                                ...item,
                                checked: false
                              }
                            })
                          });
                        }}>
                        <FontAwesomeIcon icon={faBan} />{' '}
                        {t('Deselect All')} <strong>{this.state.pictures.filter((v, i) => v.checked).length}</strong>
                      </Button>
                    }
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Scrollbar style={{ width: '100%', height: '64.9vh' }} noScrollX ref={this.scrollMedia}
                  onScroll={(scrollValues, prevScrollValues) => {
                    console.log('scrollValues', scrollValues);
                    let { scrollHeight, scrollTop } = scrollValues
                    let reachBottom = scrollHeight - scrollTop
                    if (false && reachBottom < 500) {
                      this.getPictures()
                    }
                  }}>

                  <Row className='row-cols-5'>
                    {pictures.map((item, index) => {
                      let pictureURL = (item.utm_url)
                        ? item.utm_url
                        : (item.utm_folder)
                          ? portalURL + this.props.authData.centerData.center_uuid + '/' + item.utm_folder + '/' + item.utm_name
                          : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline_media/' + item.utm_name
                      return <Col
                        className='mb-3'
                        key={index}>
                        <div
                          className='border position-relative album-picture'
                          style={{
                            borderRadius: '5px', height: '240px', width: '100%', backgroundPosition: 'center center',
                            backgroundImage:
                              'url(' + pictureURL + ')', backgroundSize: 'cover',
                          }}>
                          <Button onClick={() => this.openImageViewer(index)}
                            variant='warning'
                            className='media-view-btn'>
                            <FontAwesomeIcon icon={faEye} style={{ fontSize: 12 }} color={Black} />
                          </Button>
                          <Button
                            onClick={() => this.handleDownload(pictureURL, item.utm_name)}
                            variant='primary'
                            className='media-dl-btn'>
                            <FontAwesomeIcon style={{ fontSize: 12 }} icon={faDownload} color={White} />
                          </Button>
                          <Button variant='success' onClick={() => this.checkUncheck(index)}
                            className='btn btn-success picture-check-btn p-0 d-flex justify-content-center align-items-center'>
                            {item.checked ? (
                              <FontAwesomeIcon icon={faCheck} color='#ffffff' />
                            ) : (
                              <span
                                style={{ display: 'block', width: 18, height: 18, borderRadius: 9, backgroundColor: '#fff', }}></span>
                            )}
                          </Button>
                        </div>
                      </Col>
                    })}
                  </Row>

                  {imagesParams.refreshing && !imagesParams.finished &&
                    <div style={{ height: '120px', flexDirection: 'column', }} className='d-flex justify-content-center align-items-center'>
                      <span className='mb-1'>
                        <Spinner
                          animation='grow'
                          variant='dark'
                        />
                      </span>
                      {t('Fetching media...')}
                    </div>
                  }

                  {false && !imagesParams.refreshing && imagesParams.finished &&
                    <div style={{ height: '120px', flexDirection: 'column', }} className='d-flex justify-content-center align-items-center'>
                      {(pictures.length > 0) ? t('No more media...') : t('No media found...')}
                    </div>
                  }

                  {!imagesParams.finished &&
                    <div className='text-center'>
                      <Button variant="dark" disabled={imagesParams.refreshing} onClick={() => this.getPictures()}>Load More</Button>
                    </div>
                  }


                </Scrollbar>
              </Card.Body>
            </Card>
          </div>
        </div>
        {this.state.isViewerOpen && (
          <ImageViewer
            backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
            src={this.state.images}
            currentIndex={this.state.currentIndex}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={this.closeImageViewer}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(withParams(Album));
