/**
 * Selected Child Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const selectedChildSlice = createSlice({
  name: 'selectedChild',
  initialState: {
    data: {},
  },
  reducers: {
    setSelectedChild: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setSelectedChild } = selectedChildSlice.actions;
export default selectedChildSlice.reducer;
