import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './paginate.css';
import { Gray } from '../../../helpers/brand_colors_helper';

class Paginate extends Component {
  // States
  state = {
    page: 1,
    lastPage: 0,
    limit: 0,
    offset: 0,
    totalRecords: 0,
  };

  /**
   * Component Did Mount
   */
  componentDidMount() {
    const { limit, totalRecords } = this.props;
    this.setState({ lastPage: Math.ceil(totalRecords / limit), limit, totalRecords });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.totalRecords !== this.props.totalRecords) {
      this.setState({
        totalRecords: this.props.totalRecords,
        lastPage: Math.ceil(this.props.totalRecords / this.props.limit),
        page: 1,
        offset: 0,
      });
    }
  }

  /**
   * Next
   */
  next = () => {
    const { page, lastPage, limit, offset } = this.state;

    if (page < lastPage) {
      this.setState({ page: page + 1, offset: offset + limit });
      this.props.pageChange(limit, offset + limit);
    }
  };

  /**
   * Previous
   */
  prev = () => {
    const { page, limit, offset } = this.state;

    if (page > 0) {
      this.setState({ page: page - 1, offset: offset - limit });
      this.props.pageChange(limit, offset - limit);
    }
  };

  /**
   * Render Component
   * @returns {HTMLelement}
   */
  render() {
    const { page, limit, offset, totalRecords, lastPage } = this.state;

    if (totalRecords < limit) {
      return;
    }

    return (
      <div className='paginate-wrap'>
        <button disabled={page == 1} className='btn' onClick={this.prev}>
          <FontAwesomeIcon icon={faChevronLeft} color={Gray} />
        </button>
        <button disabled={page == lastPage} className='btn' onClick={this.next}>
          <FontAwesomeIcon icon={faChevronRight} color={Gray} />
        </button>
        <div className='paging-info'>
          Showing {offset + 1}-{page == lastPage ? totalRecords : limit * page} of {totalRecords}
        </div>
      </div>
    );
  }
}

export default Paginate;
