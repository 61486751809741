import React, { Component } from 'react';
import {
    Card,
    Col,
    Image,
    Row,
    Form,
    Button,
    Spinner,
    Modal,
    Badge,
    Offcanvas,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faShareAlt, faThumbsUp, faComment, faCalendarAlt, faStop, faImage, faPoll, faTasks, faListAlt, faVideo, faDesktop, faFileAlt, faClock, faBook, faTh, faTachometer, faQuestionCircle, faAward, faCalendar, faLink, faPaperclip, faChevronLeft, faChevronRight, faHeart, faArrowAltCircleRight, faArrowAltCircleLeft, faTimesCircle, faMoneyBill, faArrowUpRightFromSquare, faDotCircle, faScroll, faCheckCircle, faCircle, faPlus, faTrashCan, faEye, faEyeSlash, faAngleLeft, } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import moment from 'moment';
import ImageViewer from 'react-simple-image-viewer';
import Slider from 'react-slick';
import ReactStars from 'react-rating-stars-component';
import { toast } from 'react-toastify';
import axios from 'axios';
import { t } from '../../helpers/translation_helper';
import L0 from '../../assets/images/default.png';
import L1 from '../../assets/images/1.png';
import L2 from '../../assets/images/2.png';
import L3 from '../../assets/images/3.png';
import L4 from '../../assets/images/4.png';
import pdfAttachment from '../../assets/images/pdf.png';
import audioAttachment from '../../assets/images/audio.png';

import { CyanProcess, VividMalachite, CerisePink, White, Black, ChineseSilver, SunsetOrange, AliceBlue, OrangeRYB, generateColor, Gray, Honeydew, LavenderBlush, } from '../../helpers/brand_colors_helper';
import CommentItem from './CommentItem';
import { Chance } from 'chance';
import { handleFormErrors } from '../../helpers/form_helpers';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
const chance = new Chance();


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;
const appURL = process.env.REACT_APP_URL;

class AcademicActivityReflection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            academic_activity_reflection: {
                aar_id: null,
                ut_id: null,
                aa_id: null,
                aar_is_root: 1,
                aar_root_id: null,
                aar_comment: '',
                aar_attachment: null,
            },
            isSaving: false,
            deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
            academic_activities: null,
            imageViewer: {
                show: false,
                images: [],
                currentIndex: 0,
                toggleImageViewer: (images = [], currentIndex = 0) => {
                    console.log('images,currentIndex', images, currentIndex);
                    this.setState({ imageViewer: { ...this.state.imageViewer, show: (images.length > 0) ? true : false, images, currentIndex } })
                }
            },
            formSectionActive: {
                parent: '1',
                child: '1.1',
            },
            formSections: [
                {
                    uid: '1',
                    active: true,
                    completed: true,
                    name: 'Activity',
                    desc: null,
                    data: [
                        {
                            uid: '1.1',
                            active: false,
                            completed: true,
                            name: 'Details',
                            desc: null,
                            image: require('../../assets/images/lesson/lesson-focus.png'),
                        },
                        {
                            uid: '1.2',
                            active: false,
                            completed: false,
                            name: 'Reflections',
                            desc: null,
                            image: require('../../assets/images/lesson/lesson-focus.png'),
                        },
                        {
                            uid: '1.3',
                            active: false,
                            completed: false,
                            name: 'Questions',
                            desc: null,
                            image: require('../../assets/images/lesson/lesson-focus.png'),
                        },
                    ]
                },
            ]
        }
        this.filePickerAarAttachment = React.createRef();
        this.reflectionScrollRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.academicActivityReflectionModal.show != this.props.academicActivityReflectionModal.show && this.props.academicActivityReflectionModal.show) {
            this.getAcademicActivityDetails({ aa_id: this.props.academicActivityReflectionModal.data.ut_type_table_id, ut_id: this.props.academicActivityReflectionModal.data.ut_id })
        }
    }
    /**
      * Preview Upload File
      * @returns
      */
    readFile = (selectedFile) => {
        return new Promise(function (resolve, reject) {
            let reader = new FileReader();
            const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
            const allowedFilesDisplay = ['PDF', 'Image'];
            const allowedSize = 5000000;

            if (selectedFile.size) {
                console.log('selectedFile', selectedFile, selectedFile.type);
                if (selectedFile.size >= allowedSize) {
                    toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
                    resolve({ type: 'error' });
                    return;
                } else if (!allowedFiles.includes(selectedFile.type)) {
                    toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
                    resolve({ type: 'error' });
                    return;
                } else {
                    if (selectedFile.type.includes('image')) {
                        reader.readAsDataURL(selectedFile);
                    } else if (selectedFile.type.includes('audio')) {
                        reader.readAsArrayBuffer(selectedFile);
                    } else {
                        reader.readAsText(selectedFile);
                    }
                }

                reader.onload = async (readerEvent) => {
                    resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
                };
                reader.onerror = (error) => {
                    resolve({ type: 'error' });
                };
            } else {
                resolve({ type: 'error' });
            }
        });
    };
    addAttachment = (e) => {
        console.log('e', e);
        const selectedFiles = e.target.files;
        let allFiles = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            allFiles.push(this.readFile(selectedFiles[i]));
        }

        Promise.all(allFiles).then((values) => {
            let attachments = e.target.multiple ? this.state[e.target.name] : null;
            let selectedFiles = values;
            selectedFiles.map((v, i) => {
                if (v.data.type) {
                    if (e.target.multiple) {
                        attachments.push({ url: v.url, data: v.data });
                    } else {
                        attachments = { url: v.url, data: v.data };
                    }
                }
            });
            if (e.target.name == 'aar_attachment') {
                this.setState({ academic_activity_reflection: { ...this.state.academic_activity_reflection, [e.target.name]: attachments } })
            } else {
                this.setState({ [e.target.name]: attachments });
            }
        });
    };

    getAcademicActivityDetails = async (data) => {
        const { authData } = this.props;
        const { center_id, center_timezone } = authData.centerData;
        const { auth_key, user_id } = authData.loginData;

        console.log('data', data);
        const params = {
            aa_id: data.aa_id,
            ut_id: data.ut_id,
            user_id,
            center_id,
            auth_key: auth_key,
        };

        try {
            const res = await axios.get(
                apiURL + 'academic-activities/get-academic-activity-details',
                {
                    params,
                }
            );

            if (res) {
                this.setState({ academic_activities: res.data }, () => this.checkCompletedFormSections())
            }

        } catch (err) {
            toast.error(t('Something went wrong while fetching comments!'));
            console.log(err);
        }
    }

    checkCompletedFormSections = () => {
        let formSections = this.state.formSections;
        formSections = formSections.map((v, i) => {
            let data = v.data;
            let sectionComplete = true;
            data = data.map((vv, ii) => {
                if (vv.uid == '1.2') {
                    let subSectionComplete = true;
                    if (this.state.academic_activities.academic_activity_reflections.length > 0) {
                        subSectionComplete = true;
                    } else {
                        subSectionComplete = false;
                        sectionComplete = false;
                    }
                    return { ...vv, completed: subSectionComplete };
                }
                else {
                    sectionComplete = false;
                    return { ...vv };
                }
            })
            return { ...v, data, completed: sectionComplete };
        })
        this.setState({ formSections: formSections });
    }

    deleteAcademicActivityReflection = async () => {
        const { authData } = this.props;
        const { center_id, center_timezone } = authData.centerData;
        const { auth_key, user_id } = authData.loginData;

        const params = {
            center_id,
            auth_key: auth_key,
            aar_id: this.state.deleteModal.aar_id,
            created_by: this.state.deleteModal.created_by,
        };

        if (this.deleteAcademicActivityReflectionReq) {
            this.deleteAcademicActivityReflectionReq.abort();
        }
        this.deleteAcademicActivityReflectionReq = new AbortController();

        try {
            const res = await axios.get(
                apiURL + 'academic-activities/delete-academic-activity-reflection',
                {
                    params,
                    signal: this.deleteAcademicActivityReflectionReq.signal,
                }
            );

            console.log('deleteAcademicActivityReflection', res);
            let resData = res.data
            if (resData.type) {

            } else {
                toast[resData.messageType](t(resData.message));
            }

            this.getAcademicActivityReflection(this.props.academicActivityReflectionModal.data.ut_type_table_id, this.props.academicActivityReflectionModal.data.ut_id, this.state.academic_activity_reflection.aar_root_id)

            this.setState({
                deleteModal: {
                    ...this.state.deleteModal, show: false, aar_id: null, created_by: null
                }
            }, () => {
                this.checkCompletedFormSections()
            })

        } catch (err) {
            toast.error(t('Something went wrong while deleting record!'));
            console.log(err);
        }
    }

    getAcademicActivityReflection = async (aa_id, ut_id, aar_root_id = null) => {
        const { authData } = this.props;
        const { center_id, center_timezone } = authData.centerData;
        const { auth_key, user_id } = authData.loginData;

        const params = {
            center_id,
            user_id,
            auth_key: auth_key,
            aa_id,
            ut_id,
            aar_root_id
        };

        if (this.getAcademicActivityReflectionReq) {
            this.getAcademicActivityReflectionReq.abort();
        }
        this.getAcademicActivityReflectionReq = new AbortController();

        try {
            const res = await axios.get(
                apiURL + 'academic-activities/get-academic-activity-reflection',
                {
                    params,
                    signal: this.getAcademicActivityReflectionReq.signal,
                }
            );

            console.log('getAcademicActivityReflection', res);
            let resData = res
            this.setState({ academic_activities: { ...this.state.academic_activities, academic_activity_reflections: resData.data } }, () => this.checkCompletedFormSections())

        } catch (err) {
            toast.error(t('Something went wrong while fetching records!'));
            console.log(err);
        }
    }

    saveAcademicActivityReflection = async () => {
        this.setState({ isSaving: true });

        if (this.saveAcademicActivityReflectionReq) {
            this.saveAcademicActivityReflectionReq.abort();
        }
        this.saveAcademicActivityReflectionReq = new AbortController();

        const { auth_key, user_id, full_name, role_id } =
            this.props.authData.loginData;
        const { center_id, center_timezone } = this.props.authData.centerData;
        const { academic_activity_reflection } = this.state;

        academic_activity_reflection.ut_id = this.props.academicActivityReflectionModal.data.ut_id
        academic_activity_reflection.aa_id = this.props.academicActivityReflectionModal.data.ut_type_table_id

        const fData = new FormData();
        fData.append('user_id', user_id);
        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        if (academic_activity_reflection.aar_attachment) {
            fData.append('aar_attachment', academic_activity_reflection.aar_attachment.data);
        }
        fData.append('academic_activity_reflection', JSON.stringify(academic_activity_reflection));
        try {
            const res = await axios.post(
                apiURL + 'academic-activities/save-academic-activity-reflection',
                fData,
                {
                    params: {
                        auth_key: auth_key,
                    },
                    signal: this.saveAcademicActivityReflectionReq.signal,
                }
            );
            console.log('res', res);

            if (res.data.type) {
                this.setState({
                    academic_activity_reflection: {
                        ...this.state.academic_activity_reflection,
                        aar_comment: '',
                        aar_attachment: null,
                    },
                }, () => {
                    this.getAcademicActivityReflection(academic_activity_reflection.aa_id, academic_activity_reflection.ut_id, academic_activity_reflection.aar_root_id)
                    this.checkCompletedFormSections()
                })
            } else {
                toast.error(res.data.message);
            }

            this.setState({ isSaving: false });
        } catch (err) {
            toast.error('Something went wrong while sending message!');
            console.log(err);
            this.setState({ isSaving: false });
        }
    }

    /**
       * Render Chat Message
       * @returns {HTMLElement}
       */
    renderReflectionMessages = () => {
        const { user_id, full_name } = this.props.authData.loginData;
        let academic_activities = this.state.academic_activities;
        return academic_activities.academic_activity_reflections.map((item, index) => {

            let classname1 = 'd-flex flex-column justify-content-between align-items-start'
            let classname2 = 'd-flex flex-row justify-content-between align-items-center'
            let classname3 = 'd-flex flex-row justify-content-between'
            item.isOther = 0
            if (item.created_by != user_id) {
                classname1 = 'd-flex flex-column justify-content-between align-items-end'
                classname2 = 'd-flex flex-row justify-content-between align-items-center'
                classname2 = 'd-flex flex-row justify-content-between'
                item.isOther = 1
            }
            return <div key={index} className={classname1} style={{ width: '60%', backgroundColor: (item.isOther) ? AliceBlue : (item.seen_by) ? Honeydew : LavenderBlush, marginBottom: 10, padding: 5, borderRadius: 5 }}>
                <div className={classname2} style={{ width: '100%' }}>
                    <div className='d-flex flex-row align-items-center justify-content-center text-muted' style={{ fontSize: 12, fontWeight: 'bold' }}>
                        <div style={{ margin: '0 5px' }}>{item.createdUserData.name}</div>
                        <Badge bg={(['6', '20'].includes(item.createdUserData.role_id)) ? 'primary' : 'danger'}>{item.createdUserData.role_name}</Badge>
                        {(this.state.academic_activity_reflection.aar_root_id == item.aar_id) &&
                            <Badge bg='dark' style={{ margin: '0 10px' }}>{t('Start of Conversation')}</Badge>
                        }
                    </div>
                    <div className='text-muted' style={{ fontSize: 12, textAlign: 'right' }}>
                        <div>{moment(item.created_on).format('DD-MM-YYYY - h:mm a')}</div>
                    </div>
                </div>
                <div className={classname3} style={{ width: '100%', padding: '10px 0' }}>
                    <div>{item.aar_comment}</div>

                    {item.aar_attachment && (
                        <div style={{ height: 60, overflow: 'hidden', borderRadius: 10 }}>
                            {item.aar_attachment && (!item.aar_attachment.includes('pdf'))
                                ? (
                                    <img src={portalURL + this.props.authData.centerData.center_uuid + '/academic_activity_reflections/' + item.aar_attachment} alt={t('Preview')} style={{ height: '100%' }} onClick={() => this.state.imageViewer.toggleImageViewer([portalURL + this.props.authData.centerData.center_uuid + '/academic_activity_reflections/' + item.aar_attachment], 0)} />
                                ) :
                                (
                                    <a href={portalURL + this.props.authData.centerData.center_uuid + '/academic_activity_reflections/' + item.aar_attachment} style={{ textDecoration: 'none', color: '#6c757d', fontSize: 13, }} target='_blank'>
                                        <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                                    </a>
                                )
                            }
                        </div>
                    )}
                </div>
                <div>
                    <div className='chat-message-timestamp'
                        style={{ paddingLeft: (item.isOther) ? 10 : 0, paddingRight: (item.isOther) ? 0 : 10, }}>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center' style={{ width: '100%', fontSize: 12, }}>
                    <div>
                        {((item.totalCommentsConversationNew != null && item.totalCommentsConversationNew != 0) || ['6', '20'].includes(item.createdUserData.role_id)) && (!this.state.academic_activity_reflection.aar_root_id) &&
                            <div onClick={() => this.setState({ academic_activity_reflection: { ...this.state.academic_activity_reflection, aar_is_root: 0, aar_root_id: item.aar_id, }, }, () => this.getAcademicActivityReflection(this.props.academicActivityReflectionModal.data.ut_type_table_id, this.props.academicActivityReflectionModal.data.ut_id, item.aar_id))}>
                                <div className='text-muted cursor-pointer'>{t('View Conversation')}</div>
                                {(item.totalCommentsConversationNew != null && item.totalCommentsConversationNew != 0) &&
                                    <Badge bg='danger'>{item.totalCommentsConversationNew}</Badge>
                                }
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-row justify-content-between align-items-center' style={{ fontSize: 12, textAlign: 'right', }}>
                        <div style={{ margin: '0 10px' }}>
                            {(item.seen_by)
                                ? <FontAwesomeIcon icon={faEye} color={VividMalachite} title={'Seen by ' + item.seenUserData.name + ' @ ' + moment(item.seen_on).format('DD-MM-YYYY HH:mm:ss')} />

                                : <FontAwesomeIcon icon={faEyeSlash} color={SunsetOrange} title={t('Not Seen')} />
                            }
                        </div>
                        {(item.seen_by == null) &&
                            <div style={{ margin: '0 10px' }}>
                                <FontAwesomeIcon icon={faTrashCan} color={SunsetOrange} className='cursor-pointer' title={t('Delete Reflection')} onClick={() =>
                                    this.setState({
                                        deleteModal: {
                                            ...this.state.deleteModal, show: true, aar_id: item.aar_id, created_by: item.created_by, title: 'Are you sure?', desc: 'Delete record!', action: () => this.deleteAcademicActivityReflection()
                                        }
                                    })
                                } />
                            </div>
                        }
                    </div>
                </div>
            </div>
        });
    };




    changeToFromSection = (parent, child) => {
        this.setState(
            {
                formSectionActive: { parent: parent, child: child },
            },
            () => {
                setTimeout(() => {
                    const element = document.getElementById('form_section_' + child);
                    if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 300);
            }
        );
    };

    renderActivity = () => {
        let data = this.props.academicActivityReflectionModal.data
        let academic_activities = this.state.academic_activities
        let formSectionActive = this.state.formSectionActive

        console.log('data, formSectionActive', data, formSectionActive);
        if (data == null || academic_activities == null) {
            return null
        }
        // DETAILS 1.1
        if (formSectionActive.child == '1.1') {
            let transdisciplinary_theme = null
            if (academic_activities.academic_activity_resources) {
                transdisciplinary_theme = academic_activities.academic_activity_resources.find((v, i) => v.aar_type == 'transdisciplinary_theme')
            }
            let academicLessonAttributes = (academic_activities.academic_lesson_attributes) ? academic_activities.academic_lesson_attributes : null
            return (<>
                <Row>
                    <Col md="8">
                        <div style={{ fontSize: 16, fontWeight: 'bold', color: Black, opacity: 0.7 }}>
                            {data.ut_title}
                        </div>
                        <div style={{ fontSize: 13, color: Black, opacity: 0.7 }}>
                            {data.ut_description}
                        </div>
                    </Col>
                    <Col className='d-flex flex-column justify-content-between align-items-end'>
                        <Badge bg='success'>{moment(data.start_datetime).format('DD-MM-YYYY HH:mm:ss')}</Badge>
                        <Badge bg='danger'>{moment(data.end_datetime).format('DD-MM-YYYY HH:mm:ss')}</Badge>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={3}>
                        <div style={{ padding: '10px 10px 10px 0' }} onClick={() => this.state.imageViewer.toggleImageViewer([portalURL + this.props.authData.centerData.center_uuid + '/academic_activities/' + academic_activities.aa_image], 0)}>
                            <img src={(academic_activities.aa_image) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_activities/' + academic_activities.aa_image : 'https://via.placeholder.com/300x150?text=No+Thumbnail'} alt={t('Preview')} style={{ width: '100%' }} />
                        </div>
                    </Col>
                    <Col>
                        <div className={'d-flex flex-column align-items-center'}>
                            <div className={'d-flex flex-row align-items-center mb-1'}>
                                <span style={{ fontSize: 16, color: Black, opacity: 0.8 }}>{academic_activities.aa_name}</span>
                            </div>
                            <div style={{ width: '100%' }}>
                                {/* <div className='mb-1' style={{ fontSize: 14, color: Black, opacity: 0.7 }}><strong>{t('Activity Design') + ': '}</strong>{academic_activities.aa_desc}</div> */}
                                <div className='mb-1' style={{ fontSize: 14, color: Black, opacity: 0.7 }}><strong>{t('Central Idea') + ': '}</strong>{academic_activities.aa_desc_cidea}</div>
                                <div className='mb-1' style={{ fontSize: 14, color: Black, opacity: 0.7 }}><strong>{t('Unit') + ': '}</strong>{academic_activities.aa_desc_unit}</div>
                                <div className='mb-1' style={{ fontSize: 14, color: Black, opacity: 0.7 }}><strong>{t('General Description') + ': '}</strong>{academic_activities.aa_desc_general}</div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='div-box-theme' style={{ marginRight: 0 }}>
                            {transdisciplinary_theme && academicLessonAttributes.map((vvv, iii) => {
                                return (vvv.ala_id == transdisciplinary_theme.aar_type_id && vvv.ala_type == 'lesson_focus') &&
                                    <div className='d-flex flex-row align-items-center'>
                                        <div className='div_image'>
                                            <img src={portalURL + this.props.authData.centerData.center_uuid + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                                        </div>
                                        <div className='heading'>{vvv.ala_title}</div>
                                    </div>

                            })}

                            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                                {transdisciplinary_theme && academic_activities.academic_activity_resources && academic_activities.academic_activity_resources.map((vvv, iii) => {
                                    return (vvv.aar_type == 'transdisciplinary_theme_focus') &&
                                        (<div key={iii} className="d-flex flex-row justify-content-center">
                                            <FontAwesomeIcon icon={faCircle} color={Gray} style={{ fontSize: 12, padding: '8px' }} />
                                            <span className='title'>{vvv.aar_title}</span>
                                        </div>)
                                })}
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            {academic_activities.aa_audio && <audio src={portalURL + this.props.authData.centerData.center_uuid + + '/academic_activities/' + academic_activities.aa_audio} controls style={{ width: '100%' }} />}
                        </div>
                    </Col>
                </Row>
                <Row>
                    {/* ALL ATTACHMENTS */}
                    {academic_activities.academic_activity_attachments && academic_activities.academic_activity_attachments.map((v, i) => {
                        return (
                            <Col key={i} md='3' className='mb-1'>
                                <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                                    {/* IMAGE */}
                                    {v.aa_attach_type.includes('image') && (
                                        <>
                                            <img src={portalURL + this.props.authData.centerData.center_uuid + '/academic_activity_attachments/' + v.aa_attach_name} alt={t('Preview')} style={{ height: '100%' }} onClick={() => this.state.imageViewer.toggleImageViewer([portalURL + this.props.authData.centerData.center_uuid + '/academic_activity_attachments/' + v.aa_attach_name], 0)} />
                                        </>
                                    )}
                                    {/* AUDIO */}
                                    {v.aa_attach_type.includes('audio') && (
                                        <>
                                            <img src={audioAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                                        </>
                                    )}
                                    {/* PDF */}
                                    {v.aa_attach_type.includes('application') && (
                                        <>
                                            <img src={pdfAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                                        </>
                                    )}
                                </div>
                            </Col>
                        );
                    })}
                </Row>

                <Row>
                    <Col>
                        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
                            <span className='heading'>{'Skills'}</span>
                        </div>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {academic_activities.academic_activity_resources && academic_activities.academic_activity_resources.map((v, i) => {
                                return v.aar_type == 'skill' ? (
                                    <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                        <span className='title'>{v.aar_title}</span>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
                            <span className='heading'>{'Goals & Success Criteria'}</span>
                        </div>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {academic_activities.academic_activity_resources && academic_activities.academic_activity_resources.map((v, i) => {
                                return v.aar_type == 'success' ? (
                                    <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                        <span className='title'>{v.aar_title}</span>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
                            <span className='heading'>{'Approaches to Learning'}</span>
                        </div>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {academic_activities.academic_activity_resources && academic_activities.academic_activity_resources.map((v, i) => {
                                return v.aar_type == 'approaches_to_learning' ? (
                                    <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                        <span className='title'>{v.aar_title}: </span>
                                        <span>{v.aar_desc}</span>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
                            <span className='heading'>{'Learner Profile'}</span>
                        </div>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {academic_activities.academic_activity_resources && academic_activities.academic_activity_resources.map((v, i) => {
                                return v.aar_type == 'learner_profile' ? (
                                    <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                        <span className='title'>{v.aar_title}: </span>
                                        <span>{v.aar_desc}</span>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
                            <span className='heading'>{'Objectives'}</span>
                        </div>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {academic_activities.curriculums && academic_activities.curriculums.map((v, i) => {
                                return <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                                    <span className='title'>{v.curriculum_code}: </span>
                                </div>
                            })}
                        </div>
                    </Col>
                </Row>
                {/* <Row>
              <Col>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
                  <span className='heading'>{'Objectives'}</span>
                </div>
                <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                  {data.academic_activities.curriculums && data.academic_activities.curriculums.map((v, i) => {
                    return (
                      <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                        <span className='title'>{v.curriculum_code}</span>
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row> */}
            </>)
        }

        // Reflections 1.2
        if (formSectionActive.child == '1.2') {
            let academic_activity_reflection = this.state.academic_activity_reflection
            return <Row>
                <Col md={12}>
                    <Card className='border-0 card-blog-details' style={{ borderTopRightRadius: 20, borderBottomRightRadius: 20 }}>
                        {academic_activity_reflection.aar_root_id &&
                            <Card.Header style={{ height: '5vh' }}>
                                <div className='d-flex flex-row justify-content-start align-items-center ' >
                                    <FontAwesomeIcon icon={faAngleLeft} style={{ color: Black, opacity: 0.7 }} className="cursor-pointer" onClick={() => this.setState({ academic_activity_reflection: { ...this.state.academic_activity_reflection, aar_is_root: 1, aar_root_id: null, }, }, () => this.getAcademicActivityReflection(this.props.academicActivityReflectionModal.data.ut_type_table_id, this.props.academicActivityReflectionModal.data.ut_id))} />
                                    <div style={{ margin: '0 10px', fontSize: 15, fontWeight: 'bold', color: Black, opacity: 0.7 }}>Back</div>
                                </div>
                            </Card.Header>
                        }
                        <Card.Body>
                            <div className='d-flex flex-column-reverse' style={{ overflowY: 'scroll', height: (academic_activity_reflection.aar_root_id) ? '49vh' : '54vh' }} ref={this.reflectionScrollRef}>
                                {this.renderReflectionMessages()}
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <div style={{ gap: 10, display: 'flex', alignItems: 'flex-end' }}>
                                <div style={{ flexDirection: 'column', padding: 5, position: 'relative' }} className='pic-prev d-flex justify-content-center'>
                                    <div style={{ height: 50, width: 50, display: 'flex', overflow: 'hidden', }}>
                                        {academic_activity_reflection.aar_attachment && (!academic_activity_reflection.aar_attachment.data.type.includes('pdf')) && (
                                            <img src={academic_activity_reflection.aar_attachment.url} alt={t('Preview')} style={{ height: '100%' }} />
                                        )}
                                        {academic_activity_reflection.aar_attachment && (academic_activity_reflection.aar_attachment.data.type.includes('pdf')) && (
                                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                                        )}
                                        {academic_activity_reflection.aar_attachment == null &&
                                            (
                                                <button onClick={() => this.filePickerAarAttachment.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: Gray }} />
                                                </button>
                                            )
                                        }
                                        {academic_activity_reflection.aar_attachment && (
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                style={{ fontSize: 14, color: SunsetOrange, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: White, borderRadius: 100, padding: 3 }}
                                                onClick={() => this.setState({ academic_activity_reflection: { ...academic_activity_reflection, aar_attachment: null } })}
                                            />
                                        )}
                                    </div>
                                    <Form.Control className='d-none' ref={this.filePickerAarAttachment} type='file' onChange={this.addAttachment} name='aar_attachment' />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <Form.Control type='text' as={'textarea'} rows="2" placeholder={t('Type your reflection here...')} onChange={(e) => this.setState({ academic_activity_reflection: { ...academic_activity_reflection, aar_comment: e.target.value } })} value={academic_activity_reflection.aar_comment} />
                                </div>
                                <div>
                                    <Button disabled={this.state.isSaving} onClick={() => this.saveAcademicActivityReflection()}>
                                        {t('SEND')}
                                        {this.state.isSaving && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' style={{ margin: '0 5px' }} />}
                                    </Button>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        }

        if (formSectionActive.child == '1.3') {
            let academic_activity_questions = academic_activities.academic_activity_questions
            let academic_activity_question_options = academic_activities.academic_activity_question_options
            if (academic_activity_questions.length <= 0) {
                return <Row>
                    <Col md={12} className="text-center">
                        {t('No Questions found')}
                    </Col>
                </Row>
            }
            return <Row>
                <Col md={12} className="text-center">
                    {t('DEVELOPMENT in progress')}
                </Col>
            </Row>
        }
    }

    render() {
        console.log('thisSTATE', this.state);
        let academicActivityReflectionModal = this.props.academicActivityReflectionModal
        let formSections = this.state.formSections
        let formSectionActive = this.state.formSectionActive
        let isViewerOpen = this.state.isViewerOpen
        return <Offcanvas show={academicActivityReflectionModal.show} onHide={academicActivityReflectionModal.closeModal} style={{ height: '100%' }} placement='bottom'>
            <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
                <div className='d-flex flex-row'>
                    <span className='header-back-btn' onClick={academicActivityReflectionModal.closeModal} style={{ marginRight: 10 }}>
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} color={Black} />
                    </span>
                    <Offcanvas.Title>{t(academicActivityReflectionModal.modalTitle)}</Offcanvas.Title>
                </div>
                <div>

                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div
                    className={'d-flex flex-row grey-section'}
                    style={{
                        overflow: 'initial',
                        backgroundImage: 'url(https://academic.ieducore.com/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                        backgroundRepeat: 'repeat',
                        backgroundPosition: '10px 12px',
                        overflow: 'hidden',
                    }}
                >
                    <div style={{ backgroundColor: White, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
                        <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                            {formSections.map((v, i) => {
                                if (formSectionActive.parent == v.uid) {
                                    return (
                                        <div key={i}>
                                            <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: White, zIndex: 1, position: 'relative' }}>
                                                <span style={{ fontSize: 18, fontWeight: '600', color: Black, opacity: 0.7 }}>{v.name}</span>
                                            </div>

                                            <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px gray', marginLeft: '1.5rem' }}>
                                                {v.data.map((vv, ii) => {
                                                    return (
                                                        <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                                            <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? VividMalachite : Gray} style={{ fontSize: 12, marginRight: 5 }} />
                                                            <span style={{ fontSize: 14, color: vv.completed ? VividMalachite : Gray }}>{vv.name}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: White, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                        {this.renderActivity()}
                        <SweetAlert
                            show={this.state.deleteModal.show}
                            warning
                            showCancel
                            confirmBtnText={t('Yes, delete it!')}
                            confirmBtnBsStyle='danger'
                            title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
                            onConfirm={this.state.deleteModal.action}
                            onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
                            focusCancelBtn>
                            {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
                        </SweetAlert>
                    </Col>
                </div>

                {this.state.imageViewer.show && (
                    <ImageViewer
                        backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
                        src={this.state.imageViewer.images}
                        currentIndex={this.state.imageViewer.currentIndex}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        onClose={this.state.imageViewer.toggleImageViewer}
                    />
                )}
            </Offcanvas.Body>
        </Offcanvas>
    }
}


const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps())(AcademicActivityReflection);
