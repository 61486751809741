import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import logger from 'redux-logger'
import { combineReducers } from 'redux';
import thunk from 'redux-thunk'

import loginReducer from './slices/authSlice';
import selectedChildReducer from './slices/selectedChildSlice';
import langReducer from './slices/langSlice';
import chatsReducer from './slices/chatsSlice';
import storage from 'redux-persist/lib/storage';


const rootReducer = combineReducers({
    auth: loginReducer,
    selectedChild: selectedChildReducer,
    language: langReducer,
    chats: chatsReducer
});

const persistConfig = {
    key: 'parent-ieducore-com',
    storage,
    whitelist: ['auth', 'selectedChild', 'language']//put name of reducers you want to put in storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

/**
 * Store
 */
export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [logger, thunk]
});
export const persistor = persistStore(store)
