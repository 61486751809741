import { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './navbar.css';
import logo from '../../../assets/images/logo-thumb.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeUser, faCommentAlt, faDownload, faUser, faCalendarCheck, faChartBar, faBook, faMedal } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';

/**
 * Navigation Bar Partial Component
 */
class NavBar extends Component {
  // Render Component
  render() {
    return (
      <aside id='navbar'>
        <div className='blue-bar'>
          <div className='logo'>
            <Link to='/dashboard' title={t('Dashboard')}>
              <div
                className='logo'
                style={{
                  backgroundImage: 'url(' + this.props.authData.centerData.logo + ')',
                  backgroundSize: '100% auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  marginBottom: 10,
                }}></div>
            </Link>
          </div>
          <div style={{ display: 'flex', flex: 1 }}>
            <ul>
              <li>
                <NavLink activeclassname='active' to='/download-media' title={t('Media')}>
                  <FontAwesomeIcon icon={faDownload} color='#ffffff' />
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname='active' to='/profile' title={t('Profile')}>
                  <FontAwesomeIcon icon={faUser} color='#ffffff' />
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname='active' to='/appointments' title={t('Appointments')}>
                  <FontAwesomeIcon icon={faCalendarCheck} color='#ffffff' />
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname='active' to='/analysis' title={t('Analysis')}>
                  <FontAwesomeIcon icon={faChartBar} color='#ffffff' />
                </NavLink>
              </li>
              <li>
                <NavLink activeclassname='active' to='/report-card-generator' title={t('Report Cards')}>
                  <FontAwesomeIcon icon={faMedal} color='#ffffff' />
                </NavLink>
              </li>
            </ul>
          </div>
          <div className='logo' style={{ marginBottom: 20 }}>
            <a
              title='Educore Systems and Technologies FZE'
              href='https://ieducore.com/' target='_blank'>
              <img src={logo} alt='iEducore' />
            </a>
          </div>
        </div>
        {/* <button className='btn-chat'>
                    <span>{t('CHAT')}</span>
                    <FontAwesomeIcon
                        icon={faCommentAlt}
                        color='#00D045'
                        style={{ fontSize: 100 }}
                    />
                </button> */}
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
});

export default connect(mapStateToProps, null)(NavBar);
