import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET } from '../../api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { CyanProcess, SunsetOrange } from '../../helpers/brand_colors_helper';
import moment from 'moment';
import reportCardThumb from '../../assets/images/report-card-thumb.png'
import GeneralCard from '../../helpers/GeneralCard';
import { version } from 'react';

/**
 * ReportCardGenerator Component
 */
class ReportCardGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Reportcards: {
        openSearch: false,
        toggleSearch: () => this.setState({ Reportcards: { ...this.state.Reportcards, openSearch: !this.state.Reportcards.openSearch, search: '' } }, () => {
          if (!this.state.Reportcards.openSearch) {
            this.getReportCards()
          }
        }),
        changeSearch: (search = '') => this.setState({ Reportcards: { ...this.state.Reportcards, search } }, () => this.getReportCards()),
        search: '',
        orderName: 'Name ASC',
        order: 'reportcards.repcard_id',
        dir: 'ASC',
        changeOrder: (orderName = 'Name ASC', order = 'reportcards.repcard_id', dir = 'DESC') => this.setState({ Reportcards: { ...this.state.Reportcards, orderName, order, dir } }, () => this.getReportCards()),
        limit: 100,
        offset: 0,
        data: null,
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
    }
  }

  componentDidMount() {
    this.getReportCards()
  }

  refreshData = () => {
    this.setState({
      Reportcards: {
        ...this.state.Reportcards,
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
    }, () => this.getReportCards());
  }

  getReportCards = async () => {
    if (this.getReportCardsReq) {
      this.getReportCardsReq.abort();
    }
    this.getReportCardsReq = new AbortController();

    this.setState({
      Reportcards: {
        ...this.state.Reportcards,
        refreshing: true
      }
    })

    try {
      const res = await GET('report-card-generator/get',
        {
          params: {
            search: this.state.Reportcards.search,
            order: this.state.Reportcards.order,
            dir: this.state.Reportcards.dir,
            limit: this.state.Reportcards.limit,
            offset: this.state.Reportcards.offset,
            class_id: this.props.selectedChild.class_id,
            child_id: this.props.selectedChild.child_id,
          },
          signal: this.getReportCardsReq.signal,
        }
      );

      this.setState({
        Reportcards: {
          ...this.state.Reportcards,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        },
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));
      }
    }
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { Reportcards } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Reports')} backBtn={true} func={[this.refreshData]} showChildSelector={true} />
            {!Reportcards.data &&
              <div className='d-flex justify-content-center p-5'>
                <Spinner animation="grow" />
              </div>
            }
            {Reportcards.data && Reportcards.data.length == 0 &&
              <div className='d-flex justify-content-center p-5'>
                {t('No Data Found!')}
              </div>
            }
            <div className='d-flex flex-row flex-warp'>
              {Reportcards.data && Reportcards.data.map((v, index) => {
                let acs_ids = v.acs_ids
                console.log('process.env', process.env);

                let URL = process.env.REACT_APP_API_URL2 + "report-card-generator/generate-reportcard?" +
                  "auth_key=" + this.props.authData.authKey +
                  "&child_id=" + this.props.selectedChild.child_id +
                  "&class_id=" + this.props.selectedChild.class_id +
                  "&agegroup_id=" + this.props.selectedChild.agegroup_id +
                  "&repcard_id=" + v.repcard_id +
                  "&acs_ids=" + acs_ids +
                  "&center_id=" + v.center_id +
                  "&center_uuid=" + this.props.authData.centerData.center_uuid;
                let data = {
                  allData: v,
                  name: <div style={{ paddingTop: 5 }}>{v.repcard_pdf_title}</div>,
                  code: null,
                  text1: v.repcard_pdf_title_2,
                  text2: '',
                  description: null,
                  cardImage: reportCardThumb,
                  onClick: () => window.open(URL, '_blank'),
                }
                return <GeneralCard data={data} key={index} />;
              }
              )}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(ReportCardGenerator);
