import React, { Component } from 'react';
import {
  Card,
  Col,
  Image,
  Row,
  Form,
  Button,
  Spinner,
  Modal,
  Badge,
  Offcanvas,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faShareAlt, faThumbsUp, faComment, faCalendarAlt, faStop, faImage, faPoll, faTasks, faListAlt, faVideo, faDesktop, faFileAlt, faClock, faBook, faTh, faTachometer, faQuestionCircle, faAward, faCalendar, faLink, faPaperclip, faChevronLeft, faChevronRight, faHeart, faArrowAltCircleRight, faArrowAltCircleLeft, faTimesCircle, faMoneyBill, faArrowUpRightFromSquare, faDotCircle, faScroll, faCheckCircle, faCircle, faPlus, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player';
import moment from 'moment';
import ImageViewer from 'react-simple-image-viewer';
import Slider from 'react-slick';
import ReactStars from 'react-rating-stars-component';
import { toast } from 'react-toastify';
import axios from 'axios';
import { t } from '../../helpers/translation_helper';
import L0 from '../../assets/images/default.png';
import L1 from '../../assets/images/1.png';
import L2 from '../../assets/images/2.png';
import L3 from '../../assets/images/3.png';
import L4 from '../../assets/images/4.png';
import pdfAttachment from '../../assets/images/pdf.png';
import audioAttachment from '../../assets/images/audio.png';

import { CyanProcess, VividMalachite, CerisePink, White, Black, ChineseSilver, SunsetOrange, AliceBlue, OrangeRYB, generateColor, Gray, } from '../../helpers/brand_colors_helper';
import CommentItem from './CommentItem';
import { Chance } from 'chance';
import { handleFormErrors } from '../../helpers/form_helpers';
import AcademicActivityReflection from './AcademicActivityReflection';
const chance = new Chance();


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;
const appURL = process.env.REACT_APP_URL;


/**
 * Timeline Card Component
 */
class TimelineCard extends Component {
  constructor(props) {
    super();
    // State
    this.state = {
      currentImage: 0,
      isViewerOpen: false,
      images: [],
      liked: props.data.utt_liked,
      modal: false,
      commentsModal: false,
      utc_root_id: 0,
      comments: null,
      attachment: null,
      comment: '',
      commentSending: false,
      academicActivityReflectionModal: {
        show: false,
        modalTitle: 'Assignment',
        data: null,
        closeModal: () => this.setState({ academicActivityReflectionModal: { ...this.state.academicActivityReflectionModal, show: false } }),
        toggleModal: (modalTitle = null, data = null) => this.setState({
          academicActivityReflectionModal: { ...this.state.academicActivityReflectionModal, show: !this.state.academicActivityReflectionModal.show, modalTitle, data }
        }),
        showModal: () => this.setState({ academicActivityReflectionModal: { ...this.state.academicActivityReflectionModal, show: true } }),
      },
    }
    this.defaultTimelineTypes = {
      "Notification": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Notification",
        picture: require('../../assets/images/user-timeline/notification.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Event": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Event",
        picture: require('../../assets/images/user-timeline/event.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Newsletter": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Newsletter",
        picture: require('../../assets/images/user-timeline/newsletter.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Media": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Media",
        picture: require('../../assets/images/user-timeline/photos.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Video": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Video",
        picture: require('../../assets/images/user-timeline/videos.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Note": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Note",
        picture: require('../../assets/images/user-timeline/attachment-pink.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Online Class": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Online Class",
        picture: require('../../assets/images/user-timeline/online-class.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Resources": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Resources",
        picture: require('../../assets/images/user-timeline/download.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Policy": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Policy",
        picture: require('../../assets/images/user-timeline/policy.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Incident Report": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Incident Report",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Poll": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Poll",
        picture: require('../../assets/images/user-timeline/poll.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Survey": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Survey",
        picture: require('../../assets/images/user-timeline/survey.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Assessment": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Observation",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Agenda": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Agenda",
        picture: require('../../assets/images/user-timeline/agenda.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Assignment": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Assignment",
        picture: require('../../assets/images/user-timeline/assignment.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Daily Report": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Daily Report",
        picture: require('../../assets/images/user-timeline/daily-report.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Exam": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Exam",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Online Homework": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Homework",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Academic Activity": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Activity",
        picture: require('../../assets/images/user-timeline/activity.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Result": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Result",
        picture: require('../../assets/images/user-timeline/awards.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Academic Lesson": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Academic Lesson",
        picture: require('../../assets/images/user-timeline/activity.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
    }
  }

  /**
   * Handle Modal
   */
  handleModal = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  /**
   * Open Image Viewer
   * @param {number} index Index of the image
   * @param {number} center_id ID of the center
   * @param {string[]} images Array of images
   */
  openImageViewer = (index, center_id, images, folder, file_key) => {
    const images_arr = images.map((item) => {
      return (item.utm_url)
        ? item.utm_url
        : (item.utm_folder)
          ? portalURL + this.props.authData.centerData.center_uuid + '/' + item.utm_folder + '/' + item.utm_name
          : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline_media/' + item.utm_name
      // return (
      //   portalURL + this.props.authData.centerData.center_uuid + '/' + folder + '/' + item[file_key]
      // );
    });

    console.log(index, center_id, images_arr);

    this.setState({
      currentIndex: index,
      isViewerOpen: true,
      images: images_arr,
    });
  };

  openSingleImageViewer = (index, image) => {
    const images_arr = [image]
    this.setState({
      currentIndex: 0,
      isViewerOpen: true,
      images: images_arr,
    });
  };
  /**
   * Close Image Viewer
   */
  closeImageViewer = () => {
    this.setState({
      currentIndex: 0,
      isViewerOpen: false,
      images: [],
    });
  };

  /**
   * Like
   * @param {Event} e Event
   */
  like = (liked_value) => async (e) => {
    e.preventDefault();

    const { data, authData } = this.props;
    const { liked } = this.state;
    const newLiked = liked == liked_value ? 0 : liked_value;

    this.setState({
      liked: newLiked,
    });

    try {
      const fData = new FormData();
      fData.append('utt_id', data.utt_id);
      fData.append('utt_liked', newLiked);

      const req = await axios.post(
        apiURL + 'user-timeline/update-reaction',
        fData,
        {
          params: {
            auth_key: authData.authKey,
          },
        }
      );

      if (req.status === 200) {
        console.log(req);
      }
    } catch (err) {
      console.log(err);
    }
  };

  sendComment = async () => {
    this.setState({ commentSending: true });
    const { auth_key, user_id, full_name } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { comment, attachment, utc_is_root, utc_root_id, utp } =
      this.state;

    if (comment == '') {
      toast.error(t('Cannot send empty comment'));
      this.setState({ commentSending: false });
      return;
    }

    const fData = new FormData();
    fData.append('ut_id', utp.ut_id);
    fData.append('utt_id', utp.utt_id);
    fData.append('utc_is_root', utc_is_root);
    fData.append('utc_root_id', utc_root_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('utc_comment', comment);
    if (attachment !== null) {
      fData.append('utc_attachment', attachment);
    }

    try {
      const res = await axios.post(
        apiURL + 'user-timeline/save-user-timeline-comment',
        fData,
        {
          params: {
            auth_key: auth_key,
          },
        }
      );

      if (!res.data.type) {
        toast.error(res.data.message);
      } else {
        this.setState({
          comment: '',
          attachment: null,
          comments: (this.state.comments) ? this.state.comments.concat(res.data.data) : [res.data.data],
        });
        // this.getComments();

        // //  Send FCM
        // this.sendFCM(
        //     currentChat.user_id,
        //     `New comment from ${full_name}`,
        //     res.data.firebaseData.body,
        //     res.data.firebaseData.data,
        //     auth_key
        // );
      }

      this.setState({ commentSending: false });
    } catch (err) {
      toast.error(t('Something went wrong while sending comment!'));
      console.log(err);
      this.setState({ commentSending: false });
    }
  };

  /**
   * Set Comment Attachment
   */
  setCommentAttach = () => {
    this.commentAttach.current.click();
  };

  /**
   * Upload File Handler
   */
  uploadFile = () => {
    const file = document.querySelector('#comment-attach').files[0];

    console.log('file.type', file.type);

    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      this.setState({
        attachment: file,
      });
    } else {
      toast.error(t('Only images are allowed in attachment!'));
    }
  };

  /**
      * Get Comments
      * @param {Object} data
      */
  getComments = async () => {
    // this.setState({ comments: null });

    const { authData } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { auth_key, user_id } = authData.loginData;
    const { utc_root_id, utp } = this.state;

    console.log('this.state.comments', this.state.comments);
    const params = {
      ut_id: utp.ut_id,
      center_id,
      utc_id: (this.state.comments) ? this.state.comments[0].utc_id : 0,
      user_id,
      utc_root_id: utc_root_id,
      center_timezone,
      auth_key: auth_key,
    };

    try {
      const res = await axios.get(
        apiURL + 'user-timeline/get-user-timeline-comments',
        {
          params,
        }
      );
      this.setState({
        comments: (this.state.comments) ? (res.data.reverse()).concat(this.state.comments) : res.data.reverse(),
        commentsFinished: (res.data.length <= 0) ? true : false
      });
    } catch (err) {
      toast.error(t('Something went wrong while fetching comments!'));
      console.log(err);
    }
  };

  /**
   * Open Conversation
   * @param {Number} utc_id
   */
  openConversation = (utc_id) => {
    this.setState(
      {
        utc_is_root: 0,
        utc_root_id: utc_id,
        comments: null,
        commentsFinished: false
      },
      () => this.getComments()
    );
  };

  /**
   * Close Conversation
   */
  closeConversation = () => {
    this.setState(
      {
        utc_is_root: 1,
        utc_root_id: 0,
        comments: null,
        commentsFinished: false
      },
      () => this.getComments()
    );
  };

  handleCommentsModal = () => {
    const { commentsModal } = this.state;
    this.setState({
      commentsModal: !commentsModal,
      utc_is_root: 1,
      utc_root_id: 0,
    });
  };

  renderDescription = (data) => {
    if (data.length > 100 && this.state.readMore != true) {
      return <>
        <div dangerouslySetInnerHTML={{ __html: data.slice(0, 100) }}></div>
        <Badge bg="dark" className='cursor-pointer' onClick={() => this.setState({ readMore: true })}>Read More...</Badge>
      </>
    } else {
      return <>
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
        {data.length > 100 && <Badge bg="dark" className='cursor-pointer' onClick={() => this.setState({ readMore: false })}>Read Less...</Badge>}
      </>
    }
  }




  renderAssignment = () => {
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // State
    const { isViewerOpen, currentImage, images, liked, modal, commentsModal, comments, utc_root_id, attachment, comment, commentSending, academicActivityReflectionModal, formSections, formSectionActive } = this.state;

    // Props
    const { data, authData, dir } = this.props;

    //  Video
    let video_url;
    if (data.ut_type == 'Video') {
      if (data.user_timeline_media[0].utm_url !== null) {
        video_url = data.user_timeline_media[0].utm_url.replace(
          'videos',
          'video'
        );
      }
    }

    let liked_icon = L0;

    if (liked == 1) {
      liked_icon = L1;
    } else if (liked == 2) {
      liked_icon = L2;
    } else if (liked == 3) {
      liked_icon = L3;
    } else if (liked == 4) {
      liked_icon = L4;
    }

    return (
      <Card className='mb-3 border-0'>
        <Card.Body>
          <Row>
            <Col>
              <div className='card-type'>
                <div className='card-icon'>
                  <Image src={this.defaultTimelineTypes[data.ut_type] ? this.defaultTimelineTypes[data.ut_type].picture : this.defaultTimelineTypes["Notification"].picture} style={{ width: '100%', height: '100%' }} />
                </div>
                {t(this.defaultTimelineTypes[data.ut_type] ? this.defaultTimelineTypes[data.ut_type].name : this.defaultTimelineTypes["Notification"].name)}
              </div>
            </Col>
            <Col className='d-flex justify-content-end align-items-center'>
              {/* <a href='#' className='card-share'>
                                <FontAwesomeIcon icon={faShareAlt} /> Share
                            </a> */}
              <span className='card-share' title={'Published @ ' + moment(data.updated_on).format('DD-MM-YYYY HH:mm:ss')}>
                <span style={{ marginLeft: 5, marginRight: 5 }}><FontAwesomeIcon icon={faClock} color={VividMalachite} /></span>
                {moment(data.updated_on).from(moment(data.current_datetime))}
              </span>
            </Col>
          </Row>
          {((data.ut_type !== 'Media') &&
            ((data.ut_attachment_1 !== null && data.ut_attachment_1 !== '') || (data.ut_attachment_2 !== null && data.ut_attachment_2 !== ''))) && (
              <Row className='my-2'>
                {((data.ut_attachment_1 !== null && data.ut_attachment_1 !== '') &&
                  !['pdf', 'wav', 'mpeg', 'm4a', 'mp3', 'ogg'].includes((data.ut_attachment_1.split('.').pop()).toLowerCase())) &&
                  <Col className='d-flex justify-content-center' onClick={() =>
                    this.openSingleImageViewer(
                      0,
                      (data.ut_folder) ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline/' + data.ut_attachment_1
                    )
                  }>
                    <Image
                      // thumbnail={true}
                      className='cursor-pointer img-fit-thumb'
                      src={(data.ut_folder) ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline/' + data.ut_attachment_1}
                    />
                  </Col>
                }
                {((data.ut_attachment_2 !== null && data.ut_attachment_2 !== '') &&
                  !['pdf', 'wav', 'mpeg', 'm4a', 'mp3', 'ogg'].includes((data.ut_attachment_2.split('.').pop()).toLowerCase())) &&
                  <Col className='d-flex justify-content-center' onClick={() =>
                    this.openSingleImageViewer(
                      0,
                      (data.ut_folder) ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline/' + data.ut_attachment_2
                    )
                  }>
                    <Image
                      // thumbnail={true}
                      className='cursor-pointer img-fit-thumb'
                      src={(data.ut_folder) ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline/' + data.ut_attachment_2}
                    />
                  </Col>
                }

              </Row>
            )}
          {/* {data.ut_type == 'Newsletter' &&
                        data.ut_attachment_2 !== null &&
                        data.ut_attachment_2 !== '' && (
                            <Row>
                                <Col className='my-2'>
                                    <Row>
                                        <Col className='d-flex justify-content-center'>
                                            <Image
                                                thumbnail={true}
                                                src={
                                                   portalURL + this.props.authData.centerData.center_uuid+
                                                    '/newsletter/' +
                                                    data.ut_attachment_2
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )} */}
          {(data.ut_type == 'Media' || data.ut_type == 'Assessment') &&
            (data.user_timeline_media.length > 0) && (
              <Row>
                <Col className='my-2 d-flex justify-content-center'>
                  <Row>
                    <Col
                      className='lb-thumb'
                      onClick={() =>
                        this.openImageViewer(
                          0,
                          data.center_id,
                          data.user_timeline_media,
                          (data.utm_folder) ? data.utm_folder : 'user_timeline_media',
                          'utm_name'
                        )
                      }>
                      <Image
                        // thumbnail={true}
                        className='img-fit-thumb'
                        src={
                          (data.user_timeline_media[0].utm_url)
                            ? data.user_timeline_media[0].utm_url
                            : (data.user_timeline_media[0].utm_folder)
                              ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.user_timeline_media[0].utm_folder + '/' + data.user_timeline_media[0].utm_name
                              : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline_media/' + data.user_timeline_media[0].utm_name
                        }
                      />
                    </Col>
                    {data.user_timeline_media.length >
                      1 && (
                        <Col
                          className='ps-1 position-relative lb-thumb'
                          onClick={() =>
                            this.openImageViewer(
                              1,
                              data.center_id,
                              data.user_timeline_media,
                              (data.utm_folder) ? data.utm_folder : 'user_timeline_media',
                              'utm_name'
                            )
                          }>
                          <Image
                            // thumbnail={true}
                            className='img-fit-thumb'
                            src={
                              (data.user_timeline_media[1].utm_url)
                                ? data.user_timeline_media[1].utm_url
                                : (data.user_timeline_media[1].utm_folder)
                                  ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.user_timeline_media[1].utm_folder + '/' + data.user_timeline_media[1].utm_name
                                  : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline_media/' + data.user_timeline_media[1].utm_name
                            }
                          />
                          {data.user_timeline_media
                            .length > 2 && (
                              <div className='more-images'>
                                {data
                                  .user_timeline_media
                                  .length - 2}
                                +
                              </div>
                            )}
                        </Col>
                      )}
                  </Row>

                </Col>
              </Row>
            )}
          {data.ut_type == 'Video' && (
            <Row>
              <Col className='my-2 p-0' style={{ backgroundColor: AliceBlue }}>
                <ReactPlayer
                  url={(data.user_timeline_media[0].utm_folder == 'educorepublic') ? data.user_timeline_media[0].utm_url : `https://vimeo.com${video_url}`}
                  controls={true}
                  width='100%'
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col className='card-title'>{data.ut_title}</Col>
          </Row>
          <Row>
            <Col className='card-desc'>
              {data.ut_description && <div className='mb-1'>{this.renderDescription(data.ut_description)}</div>}
              {data.fees &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    className='me-1'
                    color={VividMalachite}
                    title={t("Fees")}
                  />
                  {data.fees}
                </p>
              }
              {(data.start_datetime && data.ut_type != 'Poll' && data.ut_type != 'Survey') &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className='me-1'
                    color={VividMalachite}
                    title={t("Start Date/Time")}
                  />
                  {moment(
                    data.start_datetime,
                    'YYYY-MM-DD HH:mm:ss '
                  ).format('dddd, MMMM Do YYYY HH:mm')}
                </p>
              }
              {(data.end_datetime && data.ut_type != 'Poll' && data.ut_type != 'Survey') &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className='me-1'
                    color={SunsetOrange}
                    title={t("End Date/Time")}
                  />
                  {moment(
                    data.end_datetime,
                    'YYYY-MM-DD HH:mm:ss '
                  ).format('dddd, MMMM Do YYYY HH:mm')}
                </p>
              }

              {data.ut_url && (
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='me-1'
                    color={VividMalachite}
                  />
                  <a
                    rel='noreferrer'
                    href={data.ut_url}
                    target='_blank'>
                    {data.ut_url}
                  </a>
                </p>
              )}
              {data.classmaterial &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faBook}
                    className='me-1'
                    color={VividMalachite}
                  />{' '}
                  {data.classmaterial.classmaterial_name}
                </p>
              }
              {data.ut_attachment_1 !== null && (data.ut_attachment_1.split('.').pop()).toLowerCase() === 'pdf' && (
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faLink}
                    className='me-1'
                    color={VividMalachite}
                  />
                  <a
                    rel='noreferrer'
                    href={(data.ut_folder) ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline/' + data.ut_attachment_1}
                    target='_blank'>
                    {t('Open PDF')}
                  </a>
                </p>
              )}
              {data.ut_attachment_2 !== null && (data.ut_attachment_2.split('.').pop()).toLowerCase() === 'pdf' && (
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faLink}
                    className='me-1'
                    color={VividMalachite}
                  />
                  <a
                    rel='noreferrer'
                    href={(data.ut_folder) ? portalURL + this.props.authData.centerData.center_uuid + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + this.props.authData.centerData.center_uuid + '/user_timeline/' + data.ut_attachment_2}
                    target='_blank'>
                    {t('Open PDF')}
                  </a>
                </p>
              )}

              {(data.ut_type == 'Academic Activity') && <>
                <p className='mb-1'>
                  <strong>{t('Activity')}:</strong>{' '}
                  {data.academic_activities.aa_name}
                </p>
              </>
              }
            </Col>
          </Row>
          {(data.ut_type == 'Exam' || data.ut_type == 'Online Homework') && data.exams && (
            <Row>
              <Col className='mb-3 exam-details'>

                <p className='mb-1' title={t('Type')}>
                  <FontAwesomeIcon
                    icon={faTh}
                    className='me-1'
                  />{' '}
                  {data.exams.examstype_name} ({data.exams.examstypegrp_name})
                </p>
                {data.exams.eqdifficulty_name &&
                  <p className='mb-1' title={t('Difficulty')}>
                    <FontAwesomeIcon
                      icon={faTachometer}
                      className='me-1'
                    />{' '}
                    <span
                      style={{
                        color: data.exams
                          .eqdifficulty_color,
                      }}>
                      {data.exams.eqdifficulty_name}
                    </span>
                  </p>
                }
                {(data.exams.total_questions && data.exams.total_questions != 0) &&
                  <p className='mb-1' title={t('Total Questions')}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className='me-1'
                    />{' '}
                    {data.exams.total_questions}
                  </p>
                }
                {data.exams.acs_name &&
                  <p className='mb-1' title={t('Subject')}>
                    <FontAwesomeIcon
                      icon={faBook}
                      className='me-1'
                    />{' '}
                    {data.exams.acs_name}
                  </p>
                }
                <p className='mb-1' title={t('Date')}>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='me-1'
                  />{' '}
                  {moment(
                    data.exams.exams_date,
                    'YYYY-MM-DD'
                  ).format('dddd, MMMM Do YYYY')}
                </p>
                <p className='mb-1' title={t('Total Grade')}>
                  <FontAwesomeIcon
                    icon={faAward}
                    className='me-1'
                  />{' '}
                  {data.exams.exams_grade} ({t('Total Grade')}
                  )
                </p>
              </Col>
            </Row>
          )}
          {(data.ut_type == 'Result') && data.examsgrade && (
            <Row>
              <Col className='mb-3 exam-details'>

                <p className='mb-1' title={t('Type')}>
                  <FontAwesomeIcon
                    icon={faTh}
                    className='me-1'
                  />{' '}
                  {data.examsgrade.examstype_name} ({data.examsgrade.examstypegrp_name})
                </p>
                {data.examsgrade.acs_name &&
                  <p className='mb-1' title={t('Subject')}>
                    <FontAwesomeIcon
                      icon={faBook}
                      className='me-1'
                    />{' '}
                    {data.examsgrade.acs_name}
                  </p>
                }
                {((data.examsgrade.examsgrade_hidden_std != 1 && this.props.authData.loginData.role_id == '20' && data.examsgrade.examsgrade_posted == 1) || (this.props.authData.loginData.role_id != '20' && data.examsgrade.examsgrade_posted == 1)) &&
                  <p className='mb-1 text-info' title={t('Your Grade')}>
                    <FontAwesomeIcon
                      icon={faAward}
                      className='me-1'
                    />{' '}
                    {data.examsgrade.examsgrade_grade} / {data.examsgrade.exams_grade}
                  </p>
                }
                {data.examsgrade.examsgrade_posted == 1 &&
                  <>
                    <p className='mb-1 text-success' title={t('Highest Grade')}>
                      <FontAwesomeIcon
                        icon={faAward}
                        className='me-1'
                      />{' '}
                      {data.examsgrade.exams_grade_high} ({t('Highest Grade')}
                      )
                    </p>
                    <p className='mb-1 text-danger' title={t('Lowest Grade')}>
                      <FontAwesomeIcon
                        icon={faAward}
                        className='me-1'
                      />{' '}
                      {data.examsgrade.exams_grade_low} ({t('Lowest Grade')}
                      )
                    </p>
                  </>
                }
              </Col>
            </Row>
          )}
          {data.ut_type == 'Incident Report' && (
            <Row>
              <Col className='mb-3 exam-details'>
                <p className='mb-1'>
                  <strong>{t('Taken To Hospital:')} </strong>
                  {data.nursereport.taken_to_hospital == 0
                    ? t('No')
                    : data.nursereport.taken_to_hospital_name || t('Yes')}
                </p>
                <p className='mb-1'>
                  <strong>{t('Taken:')} </strong>
                  {data.nursereport.taken_immediately == 0
                    ? (data.nursereport.taken_later_date) ? moment(data.nursereport.taken_later_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY') : t('Not Immediately')
                    : t('Immediately')}
                </p>
              </Col>
            </Row>
          )}
          {data.ut_type == 'Poll' && (
            <Poll data={data} authData={authData} />
          )}
          {data.ut_type == 'Survey' && (
            <Survey data={data} authData={authData} />
          )}

          {(data.ut_type == 'Assessment' && data.assess_aspect && this.props.authData.centerData.assesslog_share_attainments == 1) && <div style={{ margin: '5px 0 10px 0' }}>
            {data.assess_aspect.map((v, i) => {
              return <div style={{ marginHorizontal: '0 5px' }} key={i}>
                <span style={{ fontSize: 12, color: Black, opacity: 0.7, fontWeight: 'bold' }}>{v.learnarea_desc}</span>
                {
                  v.assess_curr.map((vv, ii) => {
                    return <div style={{ marginHorizontal: '0 5px', display: 'flex', flexDirection: 'row', marginVertical: 5 }} key={ii}>
                      <div style={{ display: 'flex', marginRight: 5, justifyContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          color={OrangeRYB}
                          style={{ fontSize: 12 }}
                        />
                      </div>
                      <span style={{ fontSize: 12, color: Black, opacity: 0.7 }}>{vv.curriculum_code}</span>
                    </div>
                  })
                }
              </div>
            })}
          </div>}
          {isViewerOpen && (
            <ImageViewer
              backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
              src={images}
              currentIndex={currentImage}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={this.closeImageViewer}
            />
          )}
        </Card.Body>
        <div className='card-footer'>
          <div className='d-flex justify-content-between align-items-center flex-row'>
            <div className='d-flex justify-content-center align-items-center p-0 position-relative'>
              <span className='like-popup-btn' style={{ cursor: 'pointer' }}>
                <img src={liked_icon} style={{ position: 'relative', top: -2 }} width="18" />
              </span>
              <div className='like-popup'>
                <img src={L1} width="20" onClick={this.like(1)} />
                <img src={L2} width="20" onClick={this.like(2)} />
                <img src={L3} width="20" onClick={this.like(3)} />
                <img src={L4} width="20" onClick={this.like(4)} />
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-center p-0 position-relative'>

              <a
                href='#'
                onClick={() => {
                  this.handleCommentsModal();
                  this.setState(
                    {
                      utp: data,
                      comments: null,
                      commentsFinished: false
                    },
                    () => this.getComments()
                  );
                }}>
                <FontAwesomeIcon
                  icon={faComment}
                  className={
                    dir == 1 ? 'ms-1' : 'me-1'
                  }
                  style={{ color: (data.totalCommentsConversationNew > 0) ? VividMalachite : (data.user_timeline_comments_total > 0) ? CyanProcess : '#a4a4a4' }}
                />
                {t('Comment')}
                {(data.totalCommentsConversationNew > 0) && (
                  <Badge
                    bg='success'
                    style={{
                      borderRadius: 20,
                      position: 'relative',
                      top: '-6px',
                      left: '2px',
                      height: 20,
                      lineHeight: '13px',
                    }}>
                    {data.totalCommentsConversationNew}
                  </Badge>
                )}
              </a>
            </div>
            {/* ASSIGNMENT AND ACADEMIC ACTIVITIES */}
            {(data.ut_type == 'Assignment' || data.ut_type == 'Academic Activity') &&
              <div className='d-flex justify-content-center align-items-center p-0 position-relative'>
                <div className='cursor-pointer'
                  onClick={() => academicActivityReflectionModal.toggleModal(data.ut_type, data)}>
                  <FontAwesomeIcon
                    icon={faScroll}
                    className={
                      dir == 1 ? 'ms-1' : 'me-1'
                    }
                    style={{ color: VividMalachite }}
                  />
                  {t('Open')}
                </div>
              </div>
            }
          </div>
        </div>



        {/* COMMENTS MODAL */}
        <Modal size='md' show={commentsModal} onHide={() => this.handleCommentsModal()}>
          <Modal.Header closeButton>
            {(utc_root_id)
              ? <span
                style={{ marginRight: '5px', fontSize: '1.5rem', cursor: 'pointer' }}
                onClick={() => this.closeConversation()}>
                <FontAwesomeIcon
                  icon={
                    dir == 1
                      ? faArrowAltCircleRight
                      : faArrowAltCircleLeft
                  }
                  color={Black}
                />
              </span>
              : null
            }
            <Modal.Title>{t('Comments')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {comments == null ? (
              <div className='d-flex flex-column py-4 align-items-center'>
                <span className='mb-1'>
                  <Spinner animation='grow' variant='dark' />
                </span>
                {t('Fetching comments...')}
              </div>
            ) : (
              <>
                {comments.length > 0 ? (
                  <>
                    {(this.state.commentsFinished)
                      ? <div className='d-flex flex-column py-4 align-items-center'>
                        <span style={{ color: ChineseSilver }}>{(utc_root_id) ? t('Start of conversation') : t('No more comments')}</span>
                      </div>
                      : <div className='d-flex flex-column py-4 align-items-center'>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.getComments()}>{t('Load more comments')}</span>
                      </div>
                    }

                    {comments.map((item, index) => (
                      <CommentItem
                        key={index}
                        data={item}
                        utc_root_id={utc_root_id}
                        openConversation={
                          this.openConversation
                        }
                        closeConversation={
                          this.closeConversation
                        }
                        index={index}
                        count={comments.length - 1}
                      />
                    ))}
                  </>
                ) : (
                  <div className='d-flex flex-column py-5 align-items-center'>
                    {t('No comments yet!')}
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                gap: 10,
                display: 'flex',
                flex: 1,
              }}>
              <input
                type='file'
                ref={this.commentAttach}
                style={{ display: 'none' }}
                id='comment-attach'
                onChange={() => this.uploadFile()}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {attachment !== null ? (
                  <button
                    style={{
                      fontSize: 20,
                      border: 0,
                      backgroundColor: 'transparent',
                    }}
                    onClick={() =>
                      this.setState({
                        attachment: null,
                      })
                    }>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color='rgb(220, 53, 69)'
                    />
                  </button>
                ) : (
                  <button
                    style={{
                      fontSize: 20,
                      border: 0,
                      backgroundColor: 'transparent',
                    }}
                    onClick={this.setCommentAttach}>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </button>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <Form.Control
                  type='text'
                  placeholder={t('Type your comment here...')}
                  onChange={(e) =>
                    this.setState({
                      comment: e.target.value,
                    })
                  }
                  value={comment}
                />
              </div>
              <div>
                <Button
                  disabled={commentSending}
                  onClick={() => this.sendComment()}>
                  {t('SEND')}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        {/* ACADEMIC ACTIVITY REFLECTION MODAL */}
        <AcademicActivityReflection academicActivityReflectionModal={this.state.academicActivityReflectionModal} />

      </Card >
    );
  }
}

export default TimelineCard;

/**
 * Survey Component
 */
// class Survey extends Component {
//   // State
//   state = {
//     working: false,
//     selectedScale: null,
//     surveyAnswers: this.props.data.survey.survey_answers,
//     surveyQuestions: this.props.data.survey.survey_questions,
//   };

//   /**
//    * Render Survey Slider
//    * @param {Object} question
//    * @param {number} survey_id
//    * @param {number} index
//    * @param {number} questions_length
//    * @returns
//    */
//   renderSurveyQSlide = (question, survey_id, index, questions_length) => {
//     const { working } = this.state;
//     return (
//       <div className='survey-slide px-1' key={index}>
//         <p>
//           <FontAwesomeIcon icon={faQuestionCircle} className='me-1' />{' '}
//           <strong>{question.survey_q_description}</strong>
//         </p>

//         {question.eqtype_id == 'MATRIX' && (
//           <div className='matrix-survey'>
//             {question.survey_question_options.map((sqo, index) => (
//               <div className='mb-2' key={index}>
//                 <Form.Label>{sqo.survey_q_opt_desc}</Form.Label>
//                 <Form.Select
//                   size='sm'
//                   defaultValue='select'
//                   onChange={(e) =>
//                     this.updateAnswer(e, question)
//                   }>
//                   <option value='select' disabled>
//                     {t('Select...')}
//                   </option>
//                   {JSON.parse(question.survey_q_answers).map(
//                     (sqa, index) => (
//                       <option
//                         key={index}
//                         value={JSON.stringify({
//                           survey_q_opt_id:
//                             sqo.survey_q_opt_id,
//                           survey_q_answer: sqa,
//                         })}>
//                         {sqa}
//                       </option>
//                     )
//                   )}
//                 </Form.Select>
//               </div>
//             ))}
//           </div>
//         )}

//         {question.eqtype_id == 'COMMENTS' && (
//           <div className='comments-survey'>
//             {question.survey_question_options.map((sqo, index) => (
//               <div className='mb-2' key={index}>
//                 <Form.Label>{sqo.survey_q_opt_desc}</Form.Label>
//                 <Form.Control
//                   size='sm'
//                   type='text'
//                   placeholder='Comment...'
//                   onChange={(e) =>
//                     this.updateAnswer(e, question, index)
//                   }
//                 />
//               </div>
//             ))}
//           </div>
//         )}

//         {question.eqtype_id == 'RATING' && (
//           <div className='rating-survey'>
//             <ReactStars
//               count={question.survey_question_options.length}
//               onChange={(newRating) =>
//                 this.updateAnswer(newRating, question)
//               }
//               size={24}
//               activeColor='#ffd700'
//             />
//           </div>
//         )}

//         {question.eqtype_id == 'SCALE' && (
//           <div className='scale-survey'>
//             {question.survey_question_options.map((sqo, index) => (
//               <label
//                 key={index}
//                 className={
//                   sqo.survey_q_opt_desc ==
//                     this.state.selectedScale
//                     ? 'selected-scale survey-scale-select'
//                     : 'survey-scale-select'
//                 }
//                 htmlFor={`scale-${sqo.survey_q_opt_id}-${survey_id}`}
//                 onClick={() =>
//                   this.setState({
//                     selectedScale: sqo.survey_q_opt_desc,
//                   })
//                 }>
//                 <input
//                   className='survey-radio'
//                   type='radio'
//                   name={`scale-${sqo.survey_q_id}`}
//                   id={`scale-${sqo.survey_q_opt_id}-${survey_id}`}
//                   value={sqo.survey_q_opt_desc}
//                   onChange={(e) =>
//                     this.updateAnswer(e, question)
//                   }
//                 />
//                 {sqo.survey_q_opt_desc}
//               </label>
//             ))}
//           </div>
//         )}

//         {question.eqtype_id == 'TF' && (
//           <div className='tf-survey'>
//             <div>
//               {question.survey_question_options.map(
//                 (sqo, index) => (
//                   <Form.Check
//                     key={index}
//                     label={sqo.survey_q_opt_desc}
//                     name={`tf-${question.survey_q_id}`}
//                     type='radio'
//                     id={`opt-${sqo.survey_q_opt_id}`}
//                     value={sqo.survey_q_opt_id}
//                     onChange={(e) =>
//                       this.updateAnswer(e, question)
//                     }
//                   />
//                 )
//               )}
//             </div>
//           </div>
//         )}

//         {question.eqtype_id == 'MRQ' && (
//           <div className='mrq-survey'>
//             {question.survey_question_options.map((sqo, index) => (
//               <div className='mrq-item' key={index}>
//                 <Form.Check
//                   label={sqo.survey_q_opt_desc}
//                   name={`mrq-${question.survey_q_id}`}
//                   id={`opt-${sqo.survey_q_opt_id}-${question.survey_q_id}`}
//                   value={sqo.survey_q_opt_id}
//                   onChange={(e) =>
//                     this.updateAnswer(e, question)
//                   }
//                 />
//               </div>
//             ))}
//           </div>
//         )}

//         {question.eqtype_id == 'MCQ' && (
//           <div className='mcq-survey'>
//             {question.survey_question_options.map((sqo, index) => (
//               <Form.Check
//                 key={index}
//                 label={sqo.survey_q_opt_desc}
//                 name={`mcq-${question.survey_q_id}`}
//                 type='radio'
//                 id={`opt-${sqo.survey_q_opt_id}`}
//                 value={sqo.survey_q_opt_id}
//                 onChange={(e) => this.updateAnswer(e, question)}
//               />
//             ))}
//           </div>
//         )}

//         <div className='survey-slide-footer'>
//           {index === questions_length - 1 ? (
//             <Button
//               type='submit'
//               variant='success'
//               className='mt-2'
//               disabled={working}
//               onClick={() => this.submitSurvey()}
//               size='sm'>
//               {t('Submit')}{' '}
//               {working ? (
//                 <Spinner
//                   as='span'
//                   animation='grow'
//                   size='sm'
//                   role='status'
//                   aria-hidden='true'
//                 />
//               ) : (
//                 ''
//               )}
//             </Button>
//           ) : (
//             index + 1 + '/' + questions_length
//           )}
//         </div>
//       </div>
//     );
//   };

//   /**
//    * Update Answer
//    * @param {*} e Event
//    * @param {Object} question Question object
//    * @param {number} index Indexing
//    */
//   updateAnswer = (e, question, index = 0) => {
//     const { surveyAnswers } = this.state;
//     const { authData } = this.props;

//     if (question.eqtype_id == 'MATRIX') {
//       const value = JSON.parse(e.target.value);
//       const findAnswer = (item) =>
//         item.survey_q_id == question.survey_q_id;
//       const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

//       if (surveyAnswersIndex !== -1) {
//         let survey_q_opt_ids = JSON.parse(
//           surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
//         );

//         let survey_q_opt_ids_index = survey_q_opt_ids.findIndex(
//           (v, i) => v.survey_q_opt_id == value.survey_q_opt_id
//         );
//         if (survey_q_opt_ids_index !== -1) {
//           survey_q_opt_ids[survey_q_opt_ids_index] = {
//             survey_q_opt_id: value.survey_q_opt_id,
//             survey_q_answer: value.survey_q_answer,
//           };
//         } else {
//           survey_q_opt_ids.push({
//             survey_q_opt_id: value.survey_q_opt_id,
//             survey_q_answer: value.survey_q_answer,
//           });
//         }
//         surveyAnswers[surveyAnswersIndex] = {
//           ...surveyAnswers[surveyAnswersIndex],
//           survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
//         };
//       } else {
//         surveyAnswers.push({
//           user_id: authData.loginData.user_id,
//           survey_id: question.survey_id,
//           survey_q_id: question.survey_q_id,
//           survey_q_opt_ids: JSON.stringify([value]),
//         });
//       }

//       this.setState({ surveyAnswers });
//     }

//     if (question.eqtype_id == 'COMMENTS') {
//       const value = e.target.value;

//       const findAnswer = (item) =>
//         item.survey_q_id == question.survey_q_id;
//       const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

//       if (surveyAnswersIndex !== -1) {
//         let survey_q_opt_ids = JSON.parse(
//           surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
//         );
//         survey_q_opt_ids[index] = value;
//         surveyAnswers[surveyAnswersIndex] = {
//           ...surveyAnswers[surveyAnswersIndex],
//           survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
//         };
//       } else {
//         surveyAnswers.push({
//           user_id: authData.loginData.user_id,
//           survey_id: question.survey_id,
//           survey_q_id: question.survey_q_id,
//           survey_q_opt_ids: JSON.stringify([value]),
//         });
//       }

//       this.setState({ surveyAnswers });
//     }

//     if (question.eqtype_id == 'MRQ') {
//       const value = e.target.value;
//       let survey_q_opt_ids = [];

//       const findAnswer = (item) =>
//         item.survey_q_id == question.survey_q_id;
//       const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

//       if (surveyAnswersIndex !== -1) {
//         survey_q_opt_ids = JSON.parse(
//           surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
//         );
//         if (survey_q_opt_ids.includes(value)) {
//           survey_q_opt_ids.splice(
//             survey_q_opt_ids.indexOf(value.survey_q_opt_id),
//             1
//           );
//         } else {
//           survey_q_opt_ids.push(value);
//         }
//         surveyAnswers[surveyAnswersIndex] = {
//           ...surveyAnswers[surveyAnswersIndex],
//           survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
//         };
//       } else {
//         surveyAnswers.push({
//           user_id: authData.loginData.user_id,
//           survey_id: question.survey_id,
//           survey_q_id: question.survey_q_id,
//           survey_q_opt_ids: JSON.stringify([value]),
//         });
//       }

//       this.setState({ surveyAnswers });
//     }

//     if (question.eqtype_id == 'MCQ') {
//       const value = e.target.value;

//       const findAnswer = (item) =>
//         item.survey_q_id == question.survey_q_id;
//       const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

//       if (surveyAnswersIndex !== -1) {
//         surveyAnswers[surveyAnswersIndex] = {
//           ...surveyAnswers[surveyAnswersIndex],
//           survey_q_opt_ids: JSON.stringify([value]),
//         };
//       } else {
//         surveyAnswers.push({
//           user_id: authData.loginData.user_id,
//           survey_id: question.survey_id,
//           survey_q_id: question.survey_q_id,
//           survey_q_opt_ids: JSON.stringify([value]),
//         });
//       }

//       this.setState({ surveyAnswers });
//     }

//     if (question.eqtype_id == 'SCALE' || question.eqtype_id == 'RATING') {
//       let value;

//       if (question.eqtype_id == 'RATING') {
//         value = e;
//       } else {
//         value = e.target.value;
//       }

//       const findAnswer = (item) =>
//         item.survey_q_id == question.survey_q_id;
//       const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

//       const survey_q_opt_ids =
//         question.survey_question_options[value].survey_q_opt_id;

//       if (surveyAnswersIndex !== -1) {
//         surveyAnswers[surveyAnswersIndex] = {
//           ...surveyAnswers[surveyAnswersIndex],
//           survey_q_opt_ids: JSON.stringify([survey_q_opt_ids]),
//         };
//       } else {
//         surveyAnswers.push({
//           user_id: authData.loginData.user_id,
//           survey_id: question.survey_id,
//           survey_q_id: question.survey_q_id,
//           survey_q_opt_ids: JSON.stringify([survey_q_opt_ids]),
//         });
//       }

//       this.setState({ surveyAnswers });
//     }
//   };

//   /**
//    * Submit Survey
//    */
//   submitSurvey = async () => {
//     this.setState({
//       working: true,
//     });
//     const { surveyAnswers } = this.state;
//     const { data, authData } = this.props;

//     console.log('survey_id', data.survey.survey_id);
//     console.log('survey_answers', surveyAnswers);
//     console.log('user_id', authData.loginData.user_id);
//     console.log('center_id', authData.centerData.center_id);
//     console.log('center_timezone', authData.centerData.center_timezone);

//     console.log('submitSurvey -> surveyAnswers', surveyAnswers);

//     try {
//       const fData = new FormData();
//       fData.append('survey_id', data.survey.survey_id);
//       fData.append('user_id', authData.loginData.user_id);
//       fData.append('center_id', authData.centerData.center_id);
//       fData.append(
//         'center_timezone',
//         authData.centerData.center_timezone
//       );
//       fData.append('survey_answers', JSON.stringify(surveyAnswers));

//       const req = await axios.post(
//         apiURL + 'user-timeline/save-survey-option-selected',
//         fData,
//         {
//           params: {
//             auth_key: authData.authKey,
//           },
//         }
//       );

//       if (req.status === 200) {
//         this.setState({
//           working: false,
//           submitted: true,
//         });
//         console.log(req);
//         toast.success(t('Survey submitted successfully!'));
//       }
//     } catch (err) {
//       toast.error(t('Something went wrong!'));
//       console.log(err);
//     }
//   };

//   /**
//    * Render Component
//    * @returns {HTMLElement}
//    */
//   render() {
//     const { data, dir } = this.props;

//     const SlickButton = ({
//       currentSlide,
//       slideCount,
//       children,
//       ...props
//     }) => (
//       <FontAwesomeIcon
//         {...props}
//         icon={props.dir == 'next' ? faChevronRight : faChevronLeft}
//         color='#EE3A81'
//       />
//     );

//     const generalSliderSettings = {
//       infinite: false,
//       speed: 500,
//       slidesToScroll: 1,
//       nextArrow: <SlickButton dir='next' />,
//       prevArrow: <SlickButton dir='prev' />,
//       slidesToShow: 1,
//     };

//     return (
//       <div className='survey-carousel'>
//         <Slider {...generalSliderSettings}>
//           {data.survey.survey_questions.map((question, index) =>
//             this.renderSurveyQSlide(
//               question,
//               data.survey.survey_id,
//               index,
//               data.survey.survey_questions.length
//             )
//           )}
//         </Slider>
//       </div>
//     );
//   }
// }
class Survey extends Component {
  // State
  state = {
    working: false,
    selectedScale: null,
    surveyAnswers: this.props.data.survey.survey_answers,
    surveyQuestions: this.props.data.survey.survey_questions,
  };

  /**
   * Render Survey Slider
   * @param {Object} question
   * @param {number} survey_id
   * @param {number} index
   * @param {number} questions_length
   * @returns
   */
  renderSurveyQSlide = (question, survey_id, index, surveyAnswers) => {
    let findAns = surveyAnswers.find((v, i) => v.survey_q_id == question.survey_q_id)
    let survey_q_opt_ids = []
    let findRating = 0
    if (findAns) {
      survey_q_opt_ids = JSON.parse(findAns.survey_q_opt_ids)
      if (question.eqtype_id == 'RATING') {
        findRating = question.survey_question_options.findIndex((vv) => vv.survey_q_opt_id == survey_q_opt_ids[0])
      }
    }
    return (
      <div className='survey-slide px-1' key={index} >
        <div style={{ borderColor: AliceBlue, borderWidth: 5, borderRadius: 10, borderStyle: 'solid' }}>
          <div style={{ backgroundColor: White, padding: 5 }}>
            <FontAwesomeIcon icon={faQuestionCircle} className='me-1' style={{ color: Black, fontSize: 13, opacity: 0.8 }} />{' '}
            <span style={{ color: Black, fontSize: 13, opacity: 0.8 }}>{question.survey_q_description}</span>
          </div>

          <div style={{ backgroundColor: White, padding: 5 }}>
            {question.eqtype_id == 'MATRIX' && (
              <div className='matrix-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => (vv.survey_q_opt_id == sqo.survey_q_opt_id))
                  return <div className='mb-2' key={index}>
                    <Form.Label>{sqo.survey_q_opt_desc}</Form.Label>
                    <Form.Select
                      size='sm'
                      onChange={(e) =>
                        this.updateAnswer(e, question)
                      }
                      defaultValue={(selectedAns) ? JSON.stringify(selectedAns) : 'select'}>
                      <option value='select' disabled>
                        {t('Select...')}
                      </option>
                      {JSON.parse(question.survey_q_answers).map(
                        (sqa, index) => (
                          <option
                            key={index}
                            value={JSON.stringify({
                              survey_q_opt_id:
                                sqo.survey_q_opt_id,
                              survey_q_answer: sqa,
                            })}>
                            {sqa}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </div>
                })}
              </div>
            )}

            {question.eqtype_id == 'COMMENTS' && (
              <div className='custom-styled-form comments-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => ii == index)
                  return <div className='mb-2' key={index}>
                    <Form.Label>{sqo.survey_q_opt_desc}</Form.Label>
                    <Form.Control
                      size='sm'
                      type='text'
                      placeholder={sqo.survey_q_opt_desc}
                      onChange={(e) =>
                        this.updateAnswer(e, question, index)
                      }
                      defaultValue={(selectedAns) ? selectedAns : ''}
                    />
                  </div>
                })}
              </div>
            )}

            {question.eqtype_id == 'RATING' && (
              <div className='rating-survey'>
                <ReactStars
                  count={question.survey_question_options.length}
                  onChange={(newRating) =>
                    this.updateAnswer(newRating, question)
                  }
                  size={24}
                  activeColor={CerisePink}
                  value={findRating + 1}
                />
              </div>
            )}

            {question.eqtype_id == 'SCALE' && (
              <div className='scale-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                  return <label
                    key={index}
                    className={(sqo.survey_q_opt_desc == this.state.selectedScale || selectedAns)
                      ? 'survey-scale-select'
                      : 'selected-scale survey-scale-select'
                    }
                    htmlFor={`scale-${sqo.survey_q_opt_id}-${survey_id}`}
                    onClick={() =>
                      this.setState({
                        selectedScale: sqo.survey_q_opt_desc,
                      })
                    }>
                    <input
                      className='survey-radio'
                      type='radio'
                      name={`scale-${sqo.survey_q_id}`}
                      id={`scale-${sqo.survey_q_opt_id}-${survey_id}`}
                      value={sqo.survey_q_opt_desc}
                      onChange={(e) =>
                        this.updateAnswer(e, question)
                      }
                      checked={(selectedAns) ? true : false}
                    />
                    {sqo.survey_q_opt_desc}
                  </label>
                })}
              </div>
            )}

            {question.eqtype_id == 'TF' && (
              <div className='tf-survey'>
                <div>
                  {question.survey_question_options.map(
                    (sqo, index) => {
                      let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                      return <Form.Check
                        key={index}
                        label={sqo.survey_q_opt_desc}
                        name={`tf-${question.survey_q_id}`}
                        type='radio'
                        id={`opt-${sqo.survey_q_opt_id}`}
                        value={sqo.survey_q_opt_id}
                        onChange={(e) =>
                          this.updateAnswer(e, question)
                        }
                        checked={(selectedAns) ? true : false}
                      />
                    }
                  )}
                </div>
              </div>
            )}

            {question.eqtype_id == 'MRQ' && (
              <Row className='mrq-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                  return <Col key={index} md="6" style={{ padding: 10 }}>
                    <div className='mrq-item d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: 10, backgroundColor: AliceBlue, position: 'relative', padding: '10px 10px 10px 20px' }} key={index}>
                      <div style={{ position: 'absolute', top: 5, left: 5 }}>
                        <Form.Check
                          name={`mrq-${question.survey_q_id}`}
                          id={`opt-${sqo.survey_q_opt_id}-${question.survey_q_id}`}
                          value={sqo.survey_q_opt_id}
                          onChange={(e) =>
                            this.updateAnswer(e, question)
                          }
                          checked={(selectedAns) ? true : false}
                        />
                      </div>
                      {sqo.survey_q_opt_attachment &&
                        <div style={{ width: 60 }}>
                          <Image src={portalURL + this.props.authData.centerData.center_uuid + + '/survey_question_options/' + sqo.survey_q_opt_attachment} style={{ width: '100%' }} />
                        </div>
                      }
                      <div style={{ color: Black, fontSize: 12, opacity: 0.8 }}>{sqo.survey_q_opt_desc}</div>
                    </div>
                  </Col>
                })}
              </Row>
            )}

            {question.eqtype_id == 'MCQ' && (
              <Row className='mcq-survey'>
                {question.survey_question_options.map((sqo, index) => {

                  let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                  return <Col key={index} md="6" style={{ padding: 10 }}>
                    <div className='d-flex flex-column justify-content-center align-items-center flex-wrap' style={{ borderRadius: 10, backgroundColor: AliceBlue, position: 'relative', padding: '10px 10px 10px 20px' }}>
                      <div style={{ position: 'absolute', top: 5, left: 5 }}>
                        <Form.Check
                          name={`mcq-${question.survey_q_id}`}
                          type='radio'
                          id={`opt-${sqo.survey_q_opt_id}`}
                          value={sqo.survey_q_opt_id}
                          onChange={(e) => this.updateAnswer(e, question)}
                          checked={(selectedAns) ? true : false}
                        />
                      </div>
                      {sqo.survey_q_opt_attachment &&
                        <div style={{ width: 60 }}>
                          <Image src={portalURL + this.props.authData.centerData.center_uuid + + '/survey_question_options/' + sqo.survey_q_opt_attachment} style={{ width: '100%' }} />
                        </div>
                      }
                      <div style={{ color: Black, fontSize: 12, opacity: 0.8 }}>{sqo.survey_q_opt_desc}</div>
                    </div>
                  </Col>
                })}
              </Row>
            )}

            {question.survey_q_comment_allow == 1 &&
              <div className='custom-styled-form'>
                <Form.Group className='mb-3 '>
                  <Form.Label>
                    {question.survey_q_comment_label}
                  </Form.Label>
                  < Form.Control as="textarea" rows={1} defaultValue={(findAns) ? findAns.survey_ans_comment : ''}
                    onBlur={(e) => this.updateComment(question, e.target.value)}
                    placeholder={question.survey_q_comment_label}
                  />
                </Form.Group>
              </div>
            }

          </div>
        </div>
      </div>
    );
  };

  updateComment = (question, value) => {
    const { surveyAnswers } = this.state;
    const { authData } = this.props;
    const surveyAnswersIndex = surveyAnswers.findIndex((v, i) => v.survey_q_id == question.survey_q_id);
    if (surveyAnswersIndex !== -1) {
      surveyAnswers[surveyAnswersIndex]['survey_ans_comment'] = value
    } else {
      surveyAnswers.push({
        user_id: authData.loginData.user_id,
        survey_id: question.survey_id,
        survey_q_id: question.survey_q_id,
        survey_q_opt_ids: JSON.stringify([]),
        survey_ans_comment: value
      });
    }
    this.setState({ surveyAnswers });
  }

  /**
   * Update Answer
   * @param {*} e Event
   * @param {Object} question Question object
   * @param {number} index Indexing
   */
  updateAnswer = (e, question, index = 0) => {
    const { surveyAnswers } = this.state;
    const { authData } = this.props;

    if (question.eqtype_id == 'MATRIX') {
      const value = JSON.parse(e.target.value);
      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id;
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

      if (surveyAnswersIndex !== -1) {
        let survey_q_opt_ids = JSON.parse(
          surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
        );

        let survey_q_opt_ids_index = survey_q_opt_ids.findIndex(
          (v, i) => v.survey_q_opt_id == value.survey_q_opt_id
        );
        if (survey_q_opt_ids_index !== -1) {
          survey_q_opt_ids[survey_q_opt_ids_index] = {
            survey_q_opt_id: value.survey_q_opt_id,
            survey_q_answer: value.survey_q_answer,
          };
        } else {
          survey_q_opt_ids.push({
            survey_q_opt_id: value.survey_q_opt_id,
            survey_q_answer: value.survey_q_answer,
          });
        }
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
        };
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        });
      }

      this.setState({ surveyAnswers });
    }

    if (question.eqtype_id == 'COMMENTS') {
      const value = e.target.value;

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id;
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

      if (surveyAnswersIndex !== -1) {
        let survey_q_opt_ids = JSON.parse(
          surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
        );
        survey_q_opt_ids[index] = value;
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
        };
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        });
      }

      this.setState({ surveyAnswers });
    }

    if (question.eqtype_id == 'MRQ') {
      const value = e.target.value;
      let survey_q_opt_ids = [];

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id;
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

      if (surveyAnswersIndex !== -1) {
        survey_q_opt_ids = JSON.parse(
          surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
        );
        if (survey_q_opt_ids.includes(value)) {
          survey_q_opt_ids.splice(
            survey_q_opt_ids.indexOf(value.survey_q_opt_id),
            1
          );
        } else {
          survey_q_opt_ids.push(value);
        }
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
        };
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        });
      }

      this.setState({ surveyAnswers });
    }

    if (question.eqtype_id == 'MCQ' || question.eqtype_id == 'TF') {
      const value = e.target.value;

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id;
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

      if (surveyAnswersIndex !== -1) {
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify([value]),
        };
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        });
      }

      this.setState({ surveyAnswers });
    }

    if (question.eqtype_id == 'SCALE' || question.eqtype_id == 'RATING') {
      let value;

      if (question.eqtype_id == 'RATING') {
        value = e;
      } else {
        value = e.target.value;
      }
      if (question.eqtype_id == 'RATING') {
        value = value - 1
      }
      console.log('RATINGvalue', value);

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id;
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer);

      const survey_q_opt_ids =
        question.survey_question_options[value].survey_q_opt_id;

      if (surveyAnswersIndex !== -1) {
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify([survey_q_opt_ids]),
        };
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([survey_q_opt_ids]),
          survey_ans_comment: ''
        });
      }

      this.setState({ surveyAnswers });
    }
  };

  /**
   * Submit Survey
   */
  submitSurvey = async () => {
    this.setState({
      working: true,
    });
    const { surveyAnswers } = this.state;
    const { data, authData } = this.props;

    try {
      const fData = new FormData();
      fData.append('survey_id', data.survey.survey_id);
      fData.append('user_id', authData.loginData.user_id);
      fData.append('center_id', authData.centerData.center_id);
      fData.append(
        'center_timezone',
        authData.centerData.center_timezone
      );
      fData.append('survey_answers', JSON.stringify(surveyAnswers));

      const req = await axios.post(
        apiURL + 'user-timeline/save-survey-option-selected',
        fData,
        {
          params: {
            auth_key: authData.authKey,
          },
        }
      );

      if (req.status === 200) {
        this.setState({
          working: false,
          submitted: true,
        });
        console.log(req);
        toast.success(t('Survey submitted successfully!'));
      }
    } catch (err) {
      toast.error(t('Something went wrong!'));
      console.log(err);
    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { data, dir } = this.props;
    const survey_not_started = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(data.survey.survey_start, 'YYYY-MM-DD HH:mm:ss'))
    const survey_ended = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(data.survey.survey_end, 'YYYY-MM-DD HH:mm:ss'))


    const SlickButton = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => (
      <FontAwesomeIcon
        {...props}
        icon={props.dir == 'next' ? faChevronRight : faChevronLeft}
        color={CerisePink}
      />
    );

    const generalSliderSettings = {
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      nextArrow: <SlickButton dir='next' />,
      prevArrow: <SlickButton dir='prev' />,
      slidesToShow: 1,
      adaptiveHeight: true,
      dots: true,
      // dotsClass: 'slick-dots-custom'
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentSurveyQuestion: newIndex })
      }
    };

    return (
      <>
        <div className='mb-1' >
          {survey_not_started && <Badge bg='warning'>Survey not started yet!</Badge>
          }
          {!survey_not_started && survey_ended && <Badge bg='danger'>Survey Ended</Badge>}
        </div>
        <div className='survey-carousel' style={{ position: 'relative' }}>
          <Slider {...generalSliderSettings}>
            {data.survey.survey_questions.map((question, index) =>
              this.renderSurveyQSlide(
                question,
                data.survey.survey_id,
                index,
                this.state.surveyAnswers
              )
            )}
          </Slider>
          <div className='survey-slide-footer'>
            {(data.survey.survey_questions.length - 1 == this.state.currentSurveyQuestion) &&
              data.survey.allow_submit_by_user &&
              !survey_not_started && !survey_ended &&
              (data.survey.survey_allow_resubmit == 1 || data.survey.survey_answers.length <= 0) &&
              (<Button
                type='submit'
                variant='success'
                className='mt-2'
                onClick={() => this.submitSurvey()}
                size='sm'
                disabled={this.state.working}>
                {t('Submit')}{' '}
                {this.state.working && <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />}
              </Button>)
            }
          </div>
        </div>
      </>
    );
  }
}

/**
 * Poll Component
 */
// class Poll extends Component {
//   // State
//   state = {
//     selectedPoll: null,
//     working: false,
//     submitted: false,
//   };

//   /**
//    * Poll Submit
//    * @param {*} e Event
//    * @returns
//    */
//   pollSubmit = async (e) => {
//     e.preventDefault();
//     this.setState({
//       working: true,
//     });
//     const { selectedPoll } = this.state;
//     const { data, authData } = this.props;
//     console.log('poll_id', data.poll.poll_id);
//     console.log('user_id', authData.loginData.user_id);
//     console.log('center_id', authData.centerData.center_id);
//     console.log('center_timezone', authData.centerData.center_timezone);
//     console.log('poll_option_selected', selectedPoll);

//     if (selectedPoll == null) {
//       this.setState({
//         working: false,
//       });
//       toast.error(t('Please select poll before submit.'));
//       return;
//     } else {
//       try {
//         const fData = new FormData();
//         fData.append('poll_id', data.poll.poll_id);
//         fData.append('user_id', authData.loginData.user_id);
//         fData.append('center_id', authData.centerData.center_id);
//         fData.append(
//           'center_timezone',
//           authData.centerData.center_timezone
//         );
//         selectedPoll['poll_opt_comment'] = '';
//         fData.append(
//           'poll_option_selected',
//           JSON.stringify(selectedPoll)
//         );

//         const req = await axios.post(
//           apiURL + 'user-timeline/save-poll-option-selected',
//           fData,
//           {
//             params: {
//               auth_key: authData.authKey,
//             },
//           }
//         );

//         if (req.status === 200) {
//           this.setState({
//             working: false,
//             submitted: true,
//           });
//           console.log(req);
//           toast.success(t('Poll submitted successfully!'));
//         }
//       } catch (err) {
//         console.log(err);
//       }
//     }
//   };

//   /**
//    * Render Component
//    * @returns {HTMLElement}
//    */
//   render() {
//     const { submitted, working } = this.state;
//     const { data } = this.props;

//     return (
//       <Form className='poll-options mb-3' onSubmit={this.pollSubmit}>
//         {data.poll.poll_options.map((opt, x) => (
//           <React.Fragment key={x}>
//             {this.props.data.poll.poll_option_selected &&
//               data.poll.poll_allow_resubmit == 0 ? (
//               <Form.Check
//                 label={opt.poll_opt_name}
//                 name={`poll-${data.poll.poll_id}`}
//                 type='radio'
//                 id={`opt-${opt.poll_opt_id}`}
//                 disabled={
//                   (this.props.data.poll
//                     .poll_option_selected &&
//                     data.poll.poll_allow_resubmit == 0) ||
//                   submitted ||
//                   working
//                 }
//                 value={opt.poll_opt_id}
//                 checked={
//                   this.props.data.poll
//                     .poll_option_selected !== false &&
//                   this.props.data.poll.poll_option_selected
//                     .poll_opt_id == opt.poll_opt_id
//                 }
//                 onChange={() =>
//                   this.setState({ selectedPoll: opt })
//                 }
//               />
//             ) : (
//               <Form.Check
//                 label={opt.poll_opt_name}
//                 name={`poll-${data.poll.poll_id}`}
//                 type='radio'
//                 id={`opt-${opt.poll_opt_id}`}
//                 onChange={() =>
//                   this.setState({ selectedPoll: opt })
//                 }
//                 disabled={
//                   (this.props.data.poll
//                     .poll_option_selected &&
//                     data.poll.poll_allow_resubmit == 0) ||
//                   submitted ||
//                   working
//                 }
//                 value={opt.poll_opt_id}
//               />
//             )}
//           </React.Fragment>
//         ))}
//         {submitted === false && (
//           <>
//             {this.props.data.poll.poll_option_selected &&
//               data.poll.poll_allow_resubmit == 1 && (
//                 <Button
//                   type='submit'
//                   variant='success'
//                   className='mt-2'
//                   disabled={working}
//                   size='sm'>
//                   {t('Submit')}{' '}
//                   {working ? (
//                     <Spinner
//                       as='span'
//                       animation='grow'
//                       size='sm'
//                       role='status'
//                       aria-hidden='true'
//                     />
//                   ) : (
//                     ''
//                   )}
//                 </Button>
//               )}
//             {this.props.data.poll.poll_option_selected == false &&
//               data.poll.poll_allow_resubmit == 0 && (
//                 <Button
//                   type='submit'
//                   variant='success'
//                   className='mt-2'
//                   disabled={working}
//                   size='sm'>
//                   {t('Submit')}{' '}
//                   {working ? (
//                     <Spinner
//                       as='span'
//                       animation='grow'
//                       size='sm'
//                       role='status'
//                       aria-hidden='true'
//                     />
//                   ) : (
//                     ''
//                   )}
//                 </Button>
//               )}
//           </>
//         )}
//       </Form>
//     );
//   }
// }
class Poll extends Component {
  constructor(props) {
    super()
    // State
    this.state = {
      poll_opt_id: (props.data.poll.poll_option_selected !== false) ? props.data.poll.poll_option_selected.poll_opt_id : null,
      poll_opt_comment: (props.data.poll.poll_option_selected !== false) ? props.data.poll.poll_option_selected.poll_opt_comment : '',
      working: false,
    };
  }


  /**
   * Poll Submit
   * @param {*} e Event
   * @returns
   */
  pollSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      working: true,
    });
    const { poll_opt_id, poll_opt_comment } = this.state;
    const { data, authData } = this.props;

    if (poll_opt_id == null) {
      this.setState({
        working: false,
      });
      toast.error(t('Please select poll option to submit.'));
      return;
    } else {
      try {
        const fData = new FormData();
        fData.append('poll_id', data.poll.poll_id);
        fData.append('user_id', authData.loginData.user_id);
        fData.append('center_id', authData.centerData.center_id);
        fData.append(
          'center_timezone',
          authData.centerData.center_timezone
        );
        fData.append('poll_option_selected', JSON.stringify({ poll_opt_id: poll_opt_id, poll_opt_comment: poll_opt_comment }));

        const res = await axios.post(
          apiURL + 'user-timeline/save-poll-option-selected',
          fData,
          {
            params: {
              auth_key: authData.authKey,
            },
          }
        );

        if (res.status === 200) {

          if (res.data.type) {
            toast.success(t(res.data.message));
            this.setState({
              working: false,
            });
          } else {
            if (res.data.data != null) {
              handleFormErrors(res.data.data);
            } else {
              toast.error(t(res.data.message));
            }
            this.setState({
              working: false,
            });
          }


          console.log(res);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };





  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { working } = this.state;
    const { data } = this.props;
    const poll_not_started = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(data.poll.poll_start, 'YYYY-MM-DD HH:mm:ss'))
    const poll_ended = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(data.poll.poll_end, 'YYYY-MM-DD HH:mm:ss'))
    return (<>
      <div className='mb-1'>
        {poll_not_started && <Badge bg='warning'>Poll not started yet!</Badge>}
        {!poll_not_started && poll_ended && <Badge bg='danger'>Poll Ended</Badge>}
      </div>
      <Form className='poll-options mb-3' onSubmit={this.pollSubmit}>

        {data.poll.poll_options.map((opt, x) => {
          return <React.Fragment key={x}>
            <Form.Check label={opt.poll_opt_name} name={`poll-${data.poll.poll_id}`} type='radio' id={`opt-${opt.poll_opt_id}`}
              disabled={
                (this.props.data.poll
                  .poll_option_selected &&
                  data.poll.poll_allow_resubmit == 0) ||
                working || !data.poll.allow_submit_by_user || poll_ended
              }
              value={opt.poll_opt_id}
              checked={this.state.poll_opt_id == opt.poll_opt_id}
              onChange={() =>
                this.setState({ poll_opt_id: opt.poll_opt_id })
              }
            />
          </React.Fragment>
        })}

        <div className='custom-styled-form'>
          <Form.Group className='mb-3 '>
            <Form.Label>
              {t('Comment')}
            </Form.Label>
            < Form.Control as="textarea" rows={1} defaultValue={this.state.poll_opt_comment} onBlur={(event) => this.setState({ poll_opt_comment: event.target.value })} disabled={
              (this.props.data.poll
                .poll_option_selected &&
                data.poll.poll_allow_resubmit == 0) ||
              working || !data.poll.allow_submit_by_user || poll_ended
            } />
          </Form.Group>
        </div>

        {data.poll.allow_submit_by_user && (
          <>
            {(
              (this.props.data.poll.poll_option_selected && data.poll.poll_allow_resubmit == 1)
              ||
              (!this.props.data.poll.poll_option_selected)
            ) && !poll_not_started && !poll_ended && (
                <Button
                  type='submit'
                  variant='success'
                  className='mt-2'
                  disabled={working}
                  size='sm'>
                  {t('Submit')}{' '}
                  {working && (
                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                  )}
                </Button>
              )}
          </>
        )}
      </Form>
    </>

    );
  }
}
