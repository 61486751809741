/**
 * Languages Slice
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
const apiURL = process.env.REACT_APP_API_URL;

let cancelToken;
export const refreshLangLabels = createAsyncThunk('language/get-language-labels', async (offset = 0) => {
    console.log('here');
    if (cancelToken) {
        cancelToken.abort();
    }
    cancelToken = new AbortController();
    try {
        let req_params = {}
        req_params = {
            model_name: 'EDUCORE PORTAL',
            filetype: 'm',
            limit: 2000,
            offset: offset
        }
        const response = await axios.get(apiURL + 'language/get-language-labels', {
            params: req_params,
            signal: cancelToken.signal,
        });
        return {
            data: response.data
        };
    } catch (error) {
        if (axios.isCancel(error)) {

        } else {

        }
        return {
            data: {
                type: false
            }
        };
    }
})

export const LangSlice = createSlice({
    name: 'language',
    initialState: {
        languages: [],
        defaultLanguage: {},
        langLabels: [],
        langRefreshing: false,
        firebaseToken: '',
    },
    reducers: {
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setDefaultLanguage: (state, action) => {
            document.getElementsByTagName('html')[0].setAttribute('dir', action.payload.lang_orientation == 1 ? 'rtl' : 'ltr');
            state.defaultLanguage = action.payload;
        },
        setLangLabels: (state, action) => {
            state.langLabels = action.payload;
        },
        setFirebaseToken: (state, action) => {
            state.firebaseToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refreshLangLabels.pending, (state, action) => {
            if (!state.langRefreshing) {
                state.langRefreshing = true;
            }
        })
        builder.addCase(refreshLangLabels.fulfilled, (state, action) => {
            if (state.langRefreshing === true && action.payload) {
                let res = action.payload.data
                if (res.type) {
                    state.langRefreshing = false;
                    if (res.data.length > 0) {
                        state.langLabels = res.data
                        // state.langLabels = state.langLabels.concat(res.data)
                    }
                } else {
                    state.langRefreshing = false;
                }
            }
        })
        builder.addCase(refreshLangLabels.rejected, (state, action) => {
            if (state.langRefreshing === true) {
                state.langRefreshing = false;
            }
        })
    }
});

export const { setLanguages, setDefaultLanguage, setLangLabels, setFirebaseToken } =
    LangSlice.actions;
export default LangSlice.reducer;
