import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyBvOpTvMo_vxMi1l3EEcd8UbbQPeW2Ktdg',
    authDomain: 'educore-portal.firebaseapp.com',
    databaseURL: 'https://educore-portal.firebaseio.com',
    projectId: 'educore-portal',
    storageBucket: 'educore-portal.appspot.com',
    messagingSenderId: '1038865124281',
    appId: '1:1038865124281:web:26a7ae36c7819a170f8468',
    measurementId: 'G-N8PGZHM0P5',
};

export const firebaseApp = initializeApp(firebaseConfig);
