import React, { Component } from 'react';
import { Container, Card, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { connect } from 'react-redux';
import './calendar.css';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

/**
 * Calendar Component
 */
class Calendar extends Component {
    // State
    state = {
        events: null,
    };

    componentDidMount() {
        this.getCalendar();
    }

    /**
     * Get Calendar
     */
    getCalendar = async (arg) => {
        if (arg == undefined) {
        } else {
            this.dateStart = arg.start
            this.dateEnd = arg.end
        }
        this.setState({
            events: null,
        });
        const { auth_key, center_id } = this.props.authData.loginData;
        const { class_id } = this.props.selectedChild;

        let request_params = {
            center_id,
            class_id,
            auth_key,
            dateStart: moment(this.dateStart).format('YYYY-MM-DD'),
            dateEnd: moment(this.dateEnd).format('YYYY-MM-DD'),
        };

        try {
            const res = await axios.get(
                apiURL + 'dashboard/get-center-calendar',
                {
                    params: request_params,
                }
            );

            const modifiedData = res.data.map((item) => {
                const doc = new DOMParser().parseFromString(item.body, 'text/html');
                return {
                    ...item,
                    title: item.title + ' - ' + (item.rawdata.acs_name ? item.rawdata.acs_name + ' - ' : '') + doc.body.textContent,
                    allDay: true,

                    // start: moment(item.start).format('YYYY-MM-DD'),
                    // end: moment(item.end).format('YYYY-MM-DD'),
                }
            });

            this.setState({
                events: modifiedData,
            });
        } catch (err) {
            toast.error('Something went wrong while fetching calendar!');
            console.log(err);
        }
    };

    // eventClick = async (arg) => {
    //     const { auth_key, center_id } = this.props.authData.loginData;
    //     const { class_id } = this.props.selectedChild;
    //     let eventData = arg.event.extendedProps

    //     let request_params = {
    //         center_id,
    //         class_id,
    //         date: eventData.rawdata.exams_date,
    //         auth_key,
    //     };

    //     try {
    //         const req = await axios.get(
    //             apiURL + 'dashboard/get-single-center-calendar',
    //             {
    //                 params: request_params,
    //             }
    //         );
    //         console.log('req', req);
    //     } catch (err) {
    //         toast.error('Something went wrong while fetching calendar!');
    //         console.log(err);
    //     }
    // };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        // Language Direction
        const { lang_orientation: dir, lang_flag } = this.props.defaultLanguage;

        // State
        const { events } = this.state;

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Calendar')} backBtn={true} func={[this.getCalendar]} showChildSelector={true} />
                        <div className='grey-section' style={{
                            padding: 5
                        }}>
                            <Card
                                className='border-0 center-calendar'
                                style={{ borderRadius: 35, overflow: 'hidden' }}>
                                <Card.Body className='p-4 position-relative'>
                                    {events == null && (
                                        <div className='loading-overlay'>
                                            <span className='mb-1'>
                                                <Spinner
                                                    animation='grow'
                                                    variant='dark'
                                                />
                                            </span>
                                            {t('Fetching events...')}
                                        </div>
                                    )}
                                    <FullCalendar
                                        height={'auto'}
                                        displayEventTime={false}
                                        slotDuration={'24:00:00'}
                                        plugins={[
                                            dayGridPlugin,
                                            interactionPlugin,
                                            timeGridPlugin,
                                            listPlugin
                                        ]}

                                        headerToolbar={{
                                            left: "today prev,next",
                                            center: "title",
                                            right: "dayGridMonth,timeGridDay,listWeek",
                                        }}
                                        initialView='listWeek'
                                        eventClick={this.eventClick}
                                        datesSet={this.getCalendar}
                                        events={events}
                                        locale={dir == 1 ? 'ar-kw' : 'en'}
                                        direction={dir == 1 ? 'rtl' : 'ltr'}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(Calendar);
