/**
 * Languages Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const ChatsSlice = createSlice({
    name: 'chats',
    initialState: {
        toggleChat: false,
        chatsCount: 0,
    },
    reducers: {
        setToggleChat: (state, action) => {
            state.toggleChat = !state.toggleChat
        },
        setChatsCount: (state, action) => {
            state.chatsCount = action.payload
        },
    },
});

export const { setToggleChat, setChatsCount } = ChatsSlice.actions;
export default ChatsSlice.reducer;


