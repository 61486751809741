import moment from 'moment';
import React, { Component } from 'react';
import {
    Col,
    Container,
    Row,
    Modal,
    Form,
    Button,
    Spinner,
    Card,
    Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCancel,
    faComments,
    faPaperclip,
    faReply,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { t } from '../../helpers/translation_helper';
import { connect } from 'react-redux';
import { withParams } from '../../helpers/params_helper';

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Comment Item Component
 */
class CommentItem extends Component {
    state = {};
    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const {
            userData,
            created_on,
            center_id,
            utc_comment,
            utc_attachment,
            utc_id,
            totalCommentsConversation,
        } = this.props.data;
        const {
            openConversation,
            closeConversation,
            utc_root_id,
            index,
            count,
        } = this.props;
        const { user_id } = this.props.authData.loginData;

        // Language Direction
        const { lang_orientation: dir } = this.props.defaultLanguage;

        // CSS Class Handling
        let comment_class = 'comment-item';

        if (utc_root_id == utc_id) {
            comment_class = 'comment-item parent-comment';
            if (count > 0) {
                comment_class =
                    'comment-item parent-comment has-child-comments';
            }
        } else {
            if (utc_root_id !== utc_id && utc_root_id == 0) {
                comment_class = 'comment-item';
            } else {
                comment_class = 'comment-item child-comment';
                if (index == count) {
                    comment_class = 'comment-item last-child-comment';
                }
            }
        }

        return (
            <div
                style={{ paddingLeft: (utc_root_id == 0 || utc_root_id == utc_id) ? 0 : 50, }}
                className={comment_class}>
                <div
                    className='commenter-dp'
                    style={{
                        backgroundImage:
                            userData.picture !== ''
                                ? 'url(' + userData.picture + ')'
                                : 'url(https://via.placeholder.com/80?text=No+Thumb)',
                        width: 50,
                        height: 50,
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: 25,
                    }}></div>
                <div
                    className='comment-body'
                    style={{
                        marginLeft: dir == 1 ? 0 : 10,
                        marginRight: dir == 1 ? 10 : 0,
                    }}>
                    <Card style={{ border: 0 }}>
                        <Card.Body
                            style={{ borderRadius: 10, paddingBottom: 10 }}>
                            <Card.Subtitle className='d-flex align-items-center'>
                                {userData.name}{' '}
                                {userData.parent_of !== null &&
                                    t('Parent of') +
                                    ' ' +
                                    userData.parent_of}{' '}
                                <Badge
                                    bg={
                                        userData.role_name == 'Parent'
                                            ? 'danger'
                                            : 'primary'
                                    }
                                    style={{
                                        borderRadius: 10,
                                        marginLeft: dir == 1 ? 0 : 7,
                                        marginRight: dir == 1 ? 7 : 0,
                                    }}>
                                    {userData.role_name}
                                </Badge>
                            </Card.Subtitle>
                            <Card.Text>{utc_comment}</Card.Text>
                            {utc_attachment !== null && (
                                <Card.Text>
                                    <a
                                        rel='noreferrer'
                                        target='_blank'
                                        href={
                                            portalURL + this.props.authData.centerData.center_uuid +
                                            '/user_timeline_comments/' +
                                            utc_attachment
                                        }
                                        style={{ textDecoration: 'none' }}>
                                        <small className='text-muted'>
                                            <FontAwesomeIcon
                                                icon={faPaperclip}
                                            />{' '}
                                            {utc_attachment}
                                        </small>
                                    </a>
                                </Card.Text>
                            )}
                            <Card.Text className='comment-footer'>
                                <small className='text-muted'>
                                    {moment(created_on).fromNow()}
                                </small>
                                {user_id == userData.user_id && (
                                    <>
                                        {utc_root_id == 0 && (
                                            <button
                                                className='btn-view-conv'
                                                onClick={() =>
                                                    openConversation(utc_id)
                                                }>
                                                <FontAwesomeIcon
                                                    icon={faReply}
                                                />{' '}
                                                {t('Reply')}{' '}
                                                <Badge
                                                    bg='secondary'
                                                    style={{
                                                        borderRadius: 10,
                                                        marginLeft:
                                                            dir == 1 ? 0 : 3,
                                                        marginRight:
                                                            dir == 1 ? 3 : 0,
                                                    }}>
                                                    {totalCommentsConversation}
                                                </Badge>
                                            </button>
                                        )}
                                        {utc_root_id !== 0 &&
                                            utc_root_id == utc_id && (
                                                <button
                                                    className='btn-cancel-conv'
                                                    onClick={() =>
                                                        closeConversation()
                                                    }>
                                                    <FontAwesomeIcon
                                                        icon={faTimesCircle}
                                                    />{' '}
                                                    {t('Cancel Reply')}
                                                </button>
                                            )}
                                    </>
                                )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});
export default connect(mapStateToProps, null)(withParams(CommentItem));
