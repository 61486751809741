import moment from 'moment';
import { Component } from 'react';
import {
    Container,
    Card,
    Table,
    Row,
    Col,
    Spinner,
    Image,
    Badge,
    Button,
    Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble, faChildren, faCrown, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Black, Gray, SunsetOrange, VividMalachite } from '../../../helpers/brand_colors_helper';
import { t } from '../../../helpers/translation_helper';
import { trimThisDown } from '../../../helpers/general_helpers';

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Chat Contact Item Component
 */
class ChatContactItem extends Component {
    // States
    state = {};

    /**
     * Render
     * @returns {HTMLElement}
     */
    render() {
        const {
            full_name,
            role,
            crole,
            employeeData,
            chat_datetime_modified,
            picture,
            center_id,
            newmessages,
            other_chat_name,
            lastChatDetail
        } = this.props.data;

        return (
            <div className='cc-item'>
                {newmessages !== undefined && parseInt(newmessages) !== 0 && (
                    <small className='chat-count'>{newmessages}</small>
                )}
                <div className='cc-thumb'>
                    <div
                        style={{
                            backgroundImage: picture ? 'url(' + portalURL + this.props.authData.centerData.center_uuid + '/employees/' + picture + ')' : 'url(https://via.placeholder.com/80?text=No+Thumb)',
                            width: 50,
                            height: 50,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            borderRadius: 25,
                        }}></div>
                </div>
                <div style={{ flex: 1 }}>
                    <div className='d-flex'>
                        <div style={{ flex: 1 }}>
                            <strong className='me-2'>{full_name}</strong>
                            <Badge
                                bg={role == 'Admin' ? 'danger' : 'info'}
                                style={{ borderRadius: 10 }}>
                                {role == 'Admin' && (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCrown}
                                            color='#F6F600'
                                        />{' '}
                                    </>
                                )}
                                {crole || role}
                            </Badge>
                        </div>
                        {chat_datetime_modified && (
                            <div
                                className='text-muted'
                                style={{ fontSize: 14 }}>
                                {moment(chat_datetime_modified).fromNow()}
                            </div>
                        )}
                    </div>
                    {other_chat_name &&
                        <div className='d-flex flex-row align-items-center'>
                            <FontAwesomeIcon icon={faUserShield} color={SunsetOrange} style={{ fontSize: 16 }} />
                            <div
                                style={{ fontSize: 12, marginLeft: 5, color: SunsetOrange, fontWeight: 'bold' }}>
                                {t('Chat with') + ' ' + other_chat_name}
                            </div>
                        </div>
                    }
                    {(lastChatDetail) &&
                        <div className='d-flex flex-row align-items-center' style={{ color: Black, opacity: 0.7, fontSize: 12 }}>
                            {(parseInt(lastChatDetail.recipient_id) != parseInt(this.props.loginData.user_id)) && <FontAwesomeIcon className='mx-1' icon={(parseInt(lastChatDetail.chat_detail_read)) ? faCheckDouble : faCheck} style={{ fontSize: 12, color: (parseInt(lastChatDetail.chat_detail_read)) ? VividMalachite : Gray }} />
                            }
                            {(parseInt(lastChatDetail.is_chat_detail_deleted))
                                ? <i>{'Message Deleted'}</i>
                                : (lastChatDetail.chat_detail_message) ? trimThisDown(lastChatDetail.chat_detail_message, 30) : 'Attachment'}
                        </div>
                    }
                    <div>
                        {employeeData.map((item, index) => (
                            <Badge
                                key={index}
                                className='me-1'
                                style={{ borderRadius: 10 }}>
                                {item.class_theme} {(item.acs_name) && ' | ' + item.acs_name}
                            </Badge>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatContactItem;
