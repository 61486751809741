import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import 'react-toastify/dist/ReactToastify.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <AppRoutes />
                <ToastContainer hideProgressBar={true} theme='colored' />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
