import axios from "axios";
import { store } from "../redux/store";
/**
 * Send Firebase Cloud Message
 * @param {Number} recipient_id
 * @param {String} title
 * @param {String} body
 * @param {Object} data
 * @param {String} auth_key
 */
export const sendCloudMessage = async (user_id, title, body, data) => {
    let state = store.getState()
    let auth_key = state.auth.authData.loginData.auth_key

    try {
        const fData = new FormData();
        fData.append('recipient_id', user_id);
        fData.append('title', title);
        fData.append('body', body);
        fData.append('data', JSON.stringify(data));

        const res = await axios.post(
            process.env.REACT_APP_API_URL + 'authentication/send-cloud-message',
            fData,
            {
                params: {
                    auth_key: auth_key,
                    appname: process.env.REACT_APP_NAME,
                },
            }
        );

        console.log('sendCloudMessage', res);

    } catch (err) {
        console.log(err);
    }
};
