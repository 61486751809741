import React, { Component } from 'react';
import { Container, Card, Row, Col, Image, Spinner, ButtonGroup, Button, Offcanvas, Form, Badge, Breadcrumb, Modal, Tabs, Tab, Table } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class ExamGrades extends Component {
  state = {}

  getExamGrades = () => {
    const { graphData } = this.props;
    let returnData = [];

    if (graphData) {

      graphData.children.map((child, child_i) => {

        const grade_total = this.getGradeTotal(child.child_id);
        const percentage = grade_total.percentage * 100;
        const grade_level = this.getGradeLevel(percentage);

        if (child.exam_attempt_id) {

          returnData.push({
            child: child.child_name,
            grade_total: grade_total,
            grade_level: grade_level.gradeslevel_name,
            grade_level_color: grade_level.gradeslevel_color,
            grade_total_percent: percentage,
          });

        }
      });

    }

    return returnData;

  }

  getGradeTotal = (child_id) => {

    const { graphData } = this.props;

    if (graphData) {

      const findExamsGrade = graphData.examsgrade.find(examgrade => examgrade.child_id == child_id);

      let percentage = findExamsGrade ? (parseFloat(findExamsGrade.examsgrade_grade) / parseFloat(findExamsGrade.examsgrade_totalgrade)) : 0;

      return findExamsGrade ? {
        examsgrade_totalgrade: findExamsGrade.examsgrade_totalgrade,
        examsgrade_grade: findExamsGrade.examsgrade_grade,
        percentage
      } : {
        examsgrade_totalgrade: 0,
        examsgrade_grade: 0,
        percentage
      };
    }
  }

  getGradeLevel = (percentage) => {
    const { graphData } = this.props;

    const findGradeLevel = graphData.gradeslevel.find(gradelvl => parseFloat(percentage) >= parseFloat(gradelvl.gradeslevel_from) && parseFloat(percentage) <= parseFloat(gradelvl.gradeslevel_to));

    return findGradeLevel;
  }

  getGradeLevelColors = () => {
    const { graphData } = this.props;
    if (graphData) {
      return graphData.gradeslevel.map(level => level.gradeslevel_color);
    }
  }

  getGradeLevelLabels = () => {
    const { graphData } = this.props;
    if (graphData) {
      return graphData.gradeslevel.map(level => level.gradeslevel_name);
    }
  }

  getGraphData = () => {
    const { graphData } = this.props;
    const examGrades = this.getExamGrades();
    const examGradeLevelsLabel = this.getGradeLevelLabels();

    if (graphData) {
      let examGradesLevelData = [];

      examGradeLevelsLabel.map((levelLabel, levelLabel_i) => {
        examGradesLevelData.push(examGrades.reduce((a, b) => {
          let c = 0;
          if (b.grade_level == levelLabel) {
            c = b.grade_total_percent;
          }
          return a += parseFloat(c);
        }, 0));
      });

      return examGradesLevelData;

    }

  }

  render() {

    const { graphData } = this.props;
    const examGrades = this.getExamGrades();

    // Chart options
    const options = {
      plugins: {
        legend: {
          display: true,
        },
      },
    };

    if (graphData) {
      return (
        <Row>
          <Col md={10}>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th width="34%">{t('Name')}</th>
                  <th width="33%">{t('Grade/Total')}</th>
                  <th width="33%">{t('Grade Level')}</th>
                </tr>
              </thead>
              <tbody>
                {examGrades.map((gradeData, gradeData_i) => (
                  <tr key={gradeData_i}>
                    <td>{gradeData.child}</td>
                    <td>{parseFloat(gradeData.grade_total.examsgrade_grade).toFixed(2)}/{parseFloat(gradeData.grade_total.examsgrade_totalgrade).toFixed(2)} ({gradeData.grade_total_percent})%</td>
                    <td>
                      <strong style={{ color: gradeData.grade_level_color }}>{gradeData.grade_level}</strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col md={2}>
            <Doughnut
              data={{
                labels: this.getGradeLevelLabels(),
                datasets: [
                  {
                    data: this.getGraphData(),
                    backgroundColor: this.getGradeLevelColors(),
                  }
                ]
              }}
              options={options}
            />
          </Col>
        </Row>
      );
    } else {
      return (<div className='text-center'>
        <Spinner size='sm' animation='grow' />
      </div>);
    }

  }
}

export default ExamGrades;