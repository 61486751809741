import React, { Component } from 'react';
import {
    Container,
    Card,
    Spinner,
    Row,
    Col,
    Button,
    Badge,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link } from 'react-router-dom';
import {
    faCalendarAlt,
    faClock,
    faExternalLink,
    faFileAlt,
    faLink,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import { withParams } from '../../helpers/params_helper';
import './assignments.css';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Assignments Component
 */
class Assignments extends Component {
    // State
    state = {
        assignments: null,
    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getAssignments();
    }

    /**
     * Get Assignments
     */
    getAssignments = async () => {
        const { authData, params } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id } = authData.centerData;
        const { selectedChild } = this.props;
        let request_params = {
            center_id,
            class_id: selectedChild.class_id,
            child_id: selectedChild.child_id,
            limit: 10,
            offset: 0,
            auth_key: auth_key,
        };

        try {
            const res = await axios.get(apiURL + 'dashboard/get-stdassign', {
                params: request_params,
            });

            console.log('res', res);

            this.setState({
                assignments: res.data.data,
            });
        } catch (err) {
            toast.error('Something went wrong while fetching projects!');
            console.log(err);
        }
    };

    /**
     * Render Assignment
     * @returns {HTMLElement}
     */
    renderAssignments = () => {
        const { assignments } = this.state;
        const assItems = assignments.map((item, index) => {
            return (
                <Col md={6} key={index} style={{ marginBottom: 20 }}>
                    <Card className='border-0'>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    {item.stdassign_picture ? (
                                        <img
                                            alt=''
                                            className='img-fluid'
                                            src={
                                                portalURL + this.props.authData.centerData.center_uuid +
                                                '/stdassign/' +
                                                item.stdassign_picture
                                            }
                                        />
                                    ) : (
                                        <img
                                            alt=''
                                            className='img-fluid'
                                            src='https://ieducore.com/educore2/web/images/assignment.jpg'
                                        />
                                    )}
                                </Col>
                                <Col>
                                    <Card.Title className='mb-2'>
                                        {item.classmaterial_name} (
                                        {t(item.material_name)})
                                    </Card.Title>
                                    <Card.Text>
                                        {item.stdassign_text1}
                                    </Card.Text>
                                    <Badge>
                                        <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                                        {t('Posted:')}{' '}
                                        {moment(item.stdassign_date).format(
                                            'DD-MM-YYYY'
                                        )}
                                    </Badge>{' '}
                                    <Badge
                                        bg='warning'
                                        className='text-dark mb-3'>
                                        <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                                        {t('Due:')}{' '}
                                        {moment(item.stdassign_duedate).format(
                                            'DD-MM-YYYY'
                                        )}
                                    </Badge>
                                    <br />
                                    {item.stdassign_text2 && (
                                        <Card.Text>
                                            <strong>{t('Note 1:')}</strong>
                                            <br />
                                            {item.stdassign_text2}
                                        </Card.Text>
                                    )}
                                    {item.stdassign_text3 && (
                                        <Card.Text>
                                            <strong>{t('Note 2:')}</strong>
                                            <br />
                                            {item.stdassign_text3}
                                        </Card.Text>
                                    )}
                                    {item.stdassign_link && (
                                        <Card.Text>
                                            <FontAwesomeIcon icon={faLink} />{' '}
                                            <a
                                                rel='noreferrer'
                                                href={item.stdassign_link}
                                                target='_blank'>
                                                {item.stdassign_link}
                                            </a>
                                        </Card.Text>
                                    )}
                                    <Link
                                        to={
                                            '/project/' +
                                            item.stdassign_detail_id +
                                            '/' +
                                            item.material_name +
                                            ' (' +
                                            item.classmaterial_name +
                                            ')'
                                        }
                                        className='btn btn-primary'>
                                        <FontAwesomeIcon icon={faFileAlt} />{' '}
                                        <span className='ms-1'>
                                            {t('Submit Assignment')}
                                        </span>
                                    </Link>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            );
        });
        return assItems;
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        // Language Direction
        const { lang_orientation: dir } = this.props.defaultLanguage;

        // State
        const { assignments } = this.state;

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Projects')} backBtn={true} />
                        {assignments !== null && assignments.length > 0 ? (
                            <div id='assignments' className='grey-section'>
                                <Row>{this.renderAssignments()}</Row>
                            </div>
                        ) : (
                            <div
                                style={{
                                    height: '120px',
                                    flexDirection: 'column',
                                }}
                                className='d-flex justify-content-center align-items-center'>
                                {assignments == null ? (
                                    <>
                                        <span className='mb-1'>
                                            <Spinner
                                                animation='grow'
                                                variant='dark'
                                            />
                                        </span>
                                        {t('Fetching projects...')}
                                    </>
                                ) : (
                                    t('No projects available!')
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});
export default connect(mapStateToProps, null)(withParams(Assignments));
