/**
 * Brand Colors
 */
export const IEDU_BLUE = '#15bbe7';
export const IEDU_PINK = '#ee3a81';
export const IEDU_PINK_HOVER = '#f1478b';
export const IEDU_GREEN = '#00d045';
export const IEDU_GREEN_HOVER = '#18e05b';
export const IEDU_NAVY = '#3c63ae';
export const IEDU_BLACK = '#000000';
export const IEDU_WHITE = '#ffffff';


/**
 * Brand Colors
 */
export const CyanProcess = '#15bbe7';
export const CerisePink = '#ee3a81';
export const FrenchRose = '#f1478b';
export const VividMalachite = '#00d045';
export const Malachite = '#18e05b';
export const BlueYonder = '#3c63ae';
export const Black = '#000000';
export const White = '#ffffff';
export const ChineseSilver = '#c9c9c9';
export const PictonBlue = '#45BCE8';
export const UFOGreen = '#4DD951';
export const BlueCrayola = '#2E72FD';
export const YaleBlue = '#154689';
export const OrangeRYB = '#F39400';
export const DarkElectricBlue = '#4A607B';
export const SunsetOrange = '#F45959';
export const Gray = '#7b7b7b';
export const AliceBlue = '#EDF9FF';
export const LavenderBlush = '#FFEDF9';
export const Honeydew = '#EDFFF3';
export const AirSuperiorityBlue = '#71A3C2';


