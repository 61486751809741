import React, { Component } from 'react';
import { Card, Col, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faEllipsisV,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Black } from '../../helpers/brand_colors_helper';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Vars
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;
const controller = 'academic-subject-lessons';
const imgDir = 'academic_subject_lessons';

/**
 * Lesson Card
 */
class LessonCard extends Component {
    // States
    state = {
        actions: false,
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const { authData } = this.props;
        const { center_id } = authData.centerData;
        const { actions } = this.state;
        const {
            acs_id,
            as_id,
            asl_afl,
            asl_attach,
            asl_chat_blog,
            asl_code,
            asl_coef,
            asl_ict_usage,
            asl_id,
            asl_link,
            asl_name,
            asl_notes1,
            asl_notes2,
            asl_notes3,
            asl_order,
            asl_period,
            asl_sen_required,
            asl_theme,
            asl_type1,
            asl_type2,
            created_by,
            created_on,
            updated_by,
            updated_on,
        } = this.props.data;

        return (
            <Col md={3} className='d-flex align-items-stretch mt-4'>
                <Card className='border-0 lesson-card'>
                    <Card.Body>
                        <Link
                            to={'/lesson-details/' + asl_id}
                            state={{
                                lessonData: this.props.data,
                            }}>
                            <div
                                style={{
                                    backgroundImage: `url(${asl_attach
                                        ? portalURL + this.props.authData.centerData.center_uuid +
                                        '/' +
                                        imgDir +
                                        '/' +
                                        asl_attach
                                        : 'https://via.placeholder.com/300x150?text=No+Thumbnail'
                                        })`,
                                }}
                                // style={{
                                //     backgroundImage: `url(${
                                //         false
                                //             ? portalURL +
                                //               center_id +
                                //               '/' +
                                //               imgDir +
                                //               '/' +
                                //               asl_attach
                                //             : 'https://via.placeholder.com/300x150?text=No+Thumbnail'
                                //     })`,
                                // }}
                                className='lesson-image'></div>
                        </Link>
                        <div className='lesson-card-header'>
                            <div className='lesson-basic-info'>
                                <strong>{asl_code}</strong>{' '}
                                {/* <span className='text-muted'>{asl_code}</span>{' '} */}
                            </div>
                        </div>
                        <div className='lesson-partial-info'>
                            <p className='lesson-topic'>{asl_name}</p>
                            {/* {lesson_attach && (
                                <p className='lesson-url'>
                                    <a href={lesson_attach} target='_blank'>
                                        {lesson_attach}
                                    </a>
                                </p>
                            )} */}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(LessonCard);
